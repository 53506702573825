import React,{useState} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Verification from "../Components/Charity/Verification";
import axios from "axios";
import Loader from '../Components/Loader/Loader';

function VerificationPage() {
    const navigate = useHistory();
    let query = new URLSearchParams(useLocation().search);
    const token = query.get("verify")
    const round = query.get("round")
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ icon, setIcon ] = useState("");

    const handleOnClick = () =>{
        if(token !== null && round !== null){
            setLoading(true);
            axios.patch(`https://nimbus-media-staging.herokuapp.com/api/v1/charity?token=${token}&round=${round}`)
            .then((res) =>{
                navigate.push("/confirm-verification");
            })
            .catch(err =>{
               setMessage(err.response.data.message);
               setIcon("M6 18L18 6M6 6l12 12");
            })
        }
        else{
            setMessage("Token or round is null");
            setIcon("M6 18L18 6M6 6l12 12");
        }
        setLoading(false);
    }
   
    return (
        <Verification
        icon={
            <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d={icon} />
            </svg>
        }
        messageHeader="Vote Verification"
        messageText1="Click button to verify"
        messageText2=""
        btnText="Back"
        btnText1="Verify"
        errorMessage={message}
        btnActive={true}
        handleClick={handleOnClick}
    >
    {
        loading && <Loader color="#FFFFFF"/>
    }
    </Verification>
    )
}

export default VerificationPage
