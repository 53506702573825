import React,{useState} from 'react';
import "./Signin.css";
import logo from "../../assets/images/NIMBUS_WHITE_LOGO.png";
import backgroundDots from "../../assets/images/smallRectangle.png";
import Axios from "axios";
import Loader from "../../Components/Loader/Loader";
import SigninFooter from '../Footer/SigninFooter';
import {Link, useHistory} from "react-router-dom";

function Signin() {
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ inputIsValid, setInputIsValid ] = useState(true);
    const [ loginDetails,setLoginDetails ] = useState(
        {
            email:"",
            password:""
    })
    const validateInput = (loginDetails) =>{
        if(loginDetails.email === "" || loginDetails.password === ""){
            return false;
        }
        return true;
    }
     const handleOnChange = (event) =>{
        event.preventDefault();
        setLoginDetails((prevState)=>{
            return{
                ...prevState , [event.target.name] : event.target.value
            }
        })
    }
    const handleOnSubmit = (event) =>{
        event.preventDefault();
        setLoading(true);
        const loginStatus = validateInput(loginDetails);
        if(loginStatus){
            Axios.post("https://nimbus-media-staging.herokuapp.com/api/v1/admin/login",loginDetails,
            {
                withCredentials: true
            })
            .then((res) =>{
                if(res.data.status === "success"){
                    setErrorMessage("");
                    setLoading(false);
                    console.log(res);
                    localStorage.setItem("token", res.data.data.token);
                    history.push("/gallery-update");
                }
            })
            .catch(err =>{
                setLoading(false);
                setInputIsValid(false);
                setErrorMessage(err.response.data.message);
            })
        }
        else{
            setInputIsValid(false);
            setLoading(false);
            setErrorMessage("Input is required!!!");
        }
    }
   
    return (
        <>
        <div className="signin-container">
        {
            loading &&
            <Loader  color={"#FFFFFF"}/>
        }
            <div className="signin-header">
                <div>
                    <Link to="/home">
                        <img className="signin-header-logo" src={logo} alt="nimbus logo"/>
                    </Link>
                    <img src={backgroundDots} alt=""  className="signin-yellow-dots"/>
                </div>
            </div>
            <div className="signin-body">
                <img src={backgroundDots} alt="" className="signin-yellow-dots"/>
                <div className="signin-inner-body">
                    <div className="signin-backgroundImg">

                    </div>
                    <div className="signin-backgroundCircle">
                    
                    </div>
                    <div className="signin-form-container">
                        <form className="signin-form" onSubmit={handleOnSubmit}>
                            <div className="signin-input-wrapper">
                                <p>Sign in</p>
                                <p className={inputIsValid ? "" : "error-message"}>{errorMessage}</p>
                                <input type="text" placeholder="Email address" name="email" className="siginup-input" onChange={handleOnChange}/>
                                <input type="password" placeholder="Password" name="password" className="siginup-input"  onChange={handleOnChange}/>
                                <button type="submit" className='border-2 border-charity-color1 hover:bg-gray-50 text-charity-color1'>Sign in  to your account </button>
                            </div>
                        </form>
                    </div>
                    <div className="signin-bottom-text">
                        <p>New to Nimbus? <Link to="/signup">Sign up</Link></p>
                        <p className="siginin-forgot-password"><Link to="/password-reset">Forgot your password?</Link></p>
                    </div>
                </div>
            </div>
            <div className="signin-footer">
                <button>Contact us</button>
                <img src={backgroundDots} alt="" className="signin-yellow-dots" />
            </div>
        </div>
        <SigninFooter />
        </>
    )
}

export default Signin
