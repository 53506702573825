import React,{useState,useEffect, useRef} from "react";
import { useHistory, Link} from "react-router-dom";
import "./About.css";
import { AboutData } from './AboutData';
import aboutImage from "../../assets/images/nimbus_about_img.png";
import AboutTemplate from './AboutTemplate';
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { SliderData } from "../Header/SliderData";
import { Data } from "./WhatWeStandFor";

function About({param}) {
    const myRef = useRef(null)
    const [numOfSlideToShow,setNumOfSlideToShow] = useState(4);
    let history = useHistory();
   
    const handleResize = () => {
        if (window.innerWidth <= 600) {
            setNumOfSlideToShow(1);
            }
            else if(window.innerWidth <= 1000){
                setNumOfSlideToShow(2);
            }
            else if(window.innerWidth <= 1400){
                setNumOfSlideToShow(3);
            }
            else {
                setNumOfSlideToShow(4);
        }
    }
    
    function SampleNextArrow(props) {
    const { onClick } = props;
    return (
    <div className="about-forward-arrow-container" onClick={onClick}>
        <i className="image-nav">{<IoIosArrowForward />}</i>
    </div>
    );
    }
    
    function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="about-backward-arrow-container" onClick={onClick}>
            <i className="image-nav">{<IoIosArrowBack />}</i>
        </div>
    );
    }
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: numOfSlideToShow,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        if(param === "opportunities"){
            myRef.current.scrollIntoView();
        }
        else if(param === undefined){
            window.scrollTo(0,0);
        }
        else{
            history.push("/404");
        }
    },[])
    return (
        <div className="text-about-p-color">
            <div className="bg-gradient-to-b from-brand-color2 via-brand-color3 to-brand-color4">
                <div className="w-85 mx-auto bg-background-logo bg-no-repeat bg-contain bg-top">
                    <h1 className="text-center font-black md:py-5 py-3 text-brand-color1 md:text-4xl text-2xl">
                    ABOUT US
                    </h1>
                    <div className="block xl:flex justify-between text-center md:m-0 m-auto md:w-full w-11/12">
                        <div className="w-full h-72 md:h-72 md:w-2/5 sm:block sm:float-left xl:h-400 xl:mr-0 md:mr-10 md:mb-0 mb-4">
                            <img src={aboutImage} className="h-full w-full object-cover" alt="img"/>
                        </div>
                        <div className="lg:font-normal sm:text-lg md:leading-10 leading-6 w-full xl:w-3/5 xl:pl-14 md:text-left sm:m-0 mt-3 about-text-wrapper">
                            <p className="text-justify">Nimbus Media Limited is an <Link to={{ pathname: "https://www.nimbus.com.ng/"}} target="_blank" className="text-brand-color1 underline">outdoor media and advertising agency</Link> based in Lagos, Nigeria. We specialize in boosting brand discovery and visibility through outdoor advertising media in high-traffic locations like shopping malls, superstores, as well as leveraging branding and activations to engage your target audience.</p>
                            <p className="text-justify">Our current network of outdoor advertising media platforms include Ikeja City Mall, Novare Mall Lekki, Festival Mall Festac, Jabi lake Mall Abuja, Novare gateway Abuja, Heritage mall Ibadan, Cocoa Mall Ibadan, Ado-Bayero Mall, Kano, and all Justrite stores in Nigeria.</p>
                            <p className="text-justify">We are on our way to building the largest digital out-of-home advertising network in Africa just for you.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider-wrapper" id="mission-vission">
            <div className="slider-header">
                <h1 className="font-bold text-3xl pb-10">Our Core Values</h1>
            </div>
            <Slider {...settings}>
                {Data.map((data, index)=>{
                    return(
                        <div key={index} className="image-inner-wrapper">
                            <img className="about-images object-contain" src={data.images} alt="image3" />
                            <div className="about-content-wrapper">
                            <h1>{data.title}</h1>
                            </div> 
                        </div>
                    )
                })}
            </Slider>
            </div>
            <div className="w-full pt-4" ref={myRef}>
            {
                AboutData.map((data,id) =>{
                    return(
                        <AboutTemplate title={data.title.toUpperCase()} contentData={data.content1} imageData={data.images} _id={id} websiteURL={data.websiteURL}/>
                    )
                })
            }
            </div>
        </div>
    );
}

export default About;
