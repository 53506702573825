import axios from 'axios';
import React, {useState, useEffect,useContext} from 'react'
import InputField from './InputField';
import { IoIosClose } from 'react-icons/io';
import { TeamProfileContext } from "../../Utils/Context/teamProfileContext";

function UpdateRank({ id, handleClose}) {
    const { getTeamPositions } = useContext(TeamProfileContext);
    const [ message, setMessage ] = useState({
        msg: "",
        status: 0
    });
    const [ rankDetails, setRankDetails ] = useState(
        {
            priority: "",
            position: ""
        }
    );
    const handleOnChange = (key, value) =>{
        setRankDetails((prevState) =>{
            return{
            ...prevState, [key]: value
            }
        });
    }
    const updateMessage = (msg, status) =>{
        setMessage({
            msg: msg,
            status: status
        });
        setTimeout(() => {
            setMessage({
                msg: "",
                status: 0
            });
        }, 4000);
    }

    const UpdateRank = (payload) =>{
        axios.patch(`https://nimbus-media-staging.herokuapp.com/api/v1/rank/${id}`, payload, { withCredentials : true})
        .then((response) =>{
            console.log(response.data.status);
            if(response.data.status === "success"){
                getTeamPositions();
               updateMessage("Rank Updated Successfully !!!", 2);
            }
        })
        .catch((err) =>{
            updateMessage("Duplicate Fields !!!", 1);
        })
    }

    const handleOnSubmit = (e) =>{
        e.preventDefault();
        UpdateRank(rankDetails);
    }

    useEffect(() => {
        axios.get(`https://nimbus-media-staging.herokuapp.com/api/v1/rank/${id}`, { withCredentials : true})
        .then((response) =>{
            console.log(response.data.data.rank.priority);
            if(response.data.status === "success"){
                setRankDetails({
                    priority : response.data.data.rank.priority,
                    position: response.data.data.rank.position
                })
            }
        })
        .catch((err) =>{
            console.log(err);
        })
    }, [])
    return (
        <div className="fixed w-9/12 sm:w-2/4 md:w-2/4 lg:w-5/12 h-2/4 top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 z-75 bg-white ">
            <div className="bg-charity-color6 w-full h-10 top-0 absolute flex justify-end pr-10">
                <i className="text-white text-2xl" onClick={handleClose}><IoIosClose /></i> 
            </div>
            <form onSubmit={handleOnSubmit}> 
            <p className={`${message.status === 1 ? "bg-red-300 text-red-600 py-2 mb-2 mt-10": ""} ${message.status === 2 ? "bg-green-200 text-green-600 py-2 mb-2 mt-10": ""} pl-6`}>{message.msg}</p>
            <div className="w-3/4 pl-10 pt-1">
                <InputField type="text" name="position" label="Position Name" width="full" handleOnChange={handleOnChange} value={rankDetails.position}/>
                <InputField type="number" name="priority" label="Position Number" width="full" handleOnChange={handleOnChange} value={rankDetails.priority} />
            </div>
            <div className="bg-charity-color6 w-full h-10 bottom-0 absolute flex justify-between items-center px-7 text-white">
                <button className="bg-charity-color8 h-8 w-14 rounded-xl border hover:border-brand-color1 hover:bg-white hover:text-brand-color1" onClick={handleClose}>Cancel</button>
                <button type="submit" className="bg-charity-color8 h-8 w-14 rounded-xl">Update</button>
            </div>
            </form>
        </div>
    )
}

export default UpdateRank
