import React from 'react'
import Shimmer from './Shimmer/Shimmer'

function SkeletonGallery({ theme }) {
    const themeClass = theme || "light"
    return (
        <div className={`skeleton-gallery-wrapper ${themeClass}`}>
            
            <Shimmer />
        </div>
    )
}

export default SkeletonGallery
