import React,{useState,useEffect} from 'react'
import "./LandingPageAbout.css";
import { Link } from "react-router-dom";

import smallCircle from "../../assets/images/smallCircle.png";

import redDots  from "../../assets/images/Group 5.png";
import smallRectangle from "../../assets/images/smallRectangle.png";
import teamImage from "../../assets/images/nimbusTeamImage.png";
import smallestCircle from "../../assets/images/smallestCircle.png";
import orbitLogo from "../../assets/images/brandLogos/orbitLogo.png";
import mtnLogo from "../../assets/images/brandLogos/mtnLogo.png";
import intelLogo from "../../assets/images/brandLogos/intelLogo.png";
import pgLogo from "../../assets/images/brandLogos/pgLogo.png";
import nestleLogo from "../../assets/images/brandLogos/nestleLogo.png";
import googleLogo from "../../assets/images/brandLogos/googleLogo.png";
import emirateLogo from "../../assets/images/brandLogos/emirateLogo.png";
import maggiLogo from "../../assets/images/brandLogos/maggiLogo.png";
import cocacolaLogo from "../../assets/images/brandLogos/cocacolaLogo.png";
import frieslandLogo from "../../assets/images/brandLogos/frieslandLogo.png";
import gilletteLogo from "../../assets/images/brandLogos/gilletteLogo.png";
import smileLogo from "../../assets/images/brandLogos/smileLogo.png";
import arielLogo from "../../assets/images/brandLogos/arielLogo.png";
import samsungLogo from "../../assets/images/brandLogos/samsungLogo.png";
import pzLogo from "../../assets/images/brandLogos/pzLogo.png";
import interswitchLogo from "../../assets/images/brandLogos/interswitchLogo.png";
import hennesyLogo from "../../assets/images/brandLogos/hennesyLogo.png";
import wrigleyLogo from "../../assets/images/brandLogos/wrigleyLogo.png";
import unileverLogo from "../../assets/images/brandLogos/unileverLogo.png";
import ubaLogo from "../../assets/images/brandLogos/ubaLogo.png";
import bbcLogo from "../../assets/images/brandLogos/bbcLogo.png";


import { CgArrowLongLeft,CgArrowLongRight } from "react-icons/cg";
import redBackgroundDot from "../../assets/images/Group 5.png";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function LandingPageAbout() {
    const [numOfSlideToShow,setNumOfSlideToShow] = useState(5);
    const [ centerModeStatus, setCenterModeStatus ] = useState(true);


    
    const handleResize = () => {
        if (window.innerWidth <= 530) {
          setNumOfSlideToShow(1);
          setCenterModeStatus(false);
        }
        else if (window.innerWidth <= 700) {
            setNumOfSlideToShow(2);
            setCenterModeStatus(true);
          }
        else if (window.innerWidth <= 920) {
          setNumOfSlideToShow(3);
          setCenterModeStatus(true);
        }

        else if (window.innerWidth <= 1240) {
          setNumOfSlideToShow(4);
          setCenterModeStatus(true);
        }
        else {
            setNumOfSlideToShow(5);
        }
      }

    function SampleNextArrow(props) {
    const { onClick } = props;
    return (
    <div className="right-brand-nav">
        <i className="landing-page-image-nav" onClick={onClick}>{<CgArrowLongRight />}</i>
    </div>
    );
    }
    
    function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="left-brand-nav">
            <i className="landing-page-image-nav" onClick={onClick}>{<CgArrowLongLeft />}</i>
        </div>
    );
    }
    const brandData = [
        {
            id: 0,
            image: mtnLogo,
            title: "mtn"
        },
        {
            id: 1,
            image: pgLogo,
            title: "pg"
        },
        {
            id: 2,
            image: intelLogo,
            title: "intel"
        },
        {
            id: 3,
            image: orbitLogo,
            title: "orbit"
        },
        {
            id: 4,
            image: nestleLogo,
            title: "nestle"
        },
        {
            id: 5,
            image: googleLogo,
            title: "google"
        },
        {
            id: 6,
            image: emirateLogo,
            title: "emirate"
        },
        {
            id: 7,
            image: maggiLogo,
            title: "maggi"
        },
        {
            id: 8,
            image: cocacolaLogo,
            title: "cocacola"
        },
        {
            id: 9,
            image: frieslandLogo,
            title: "friesland"
        },
        {
            id: 10,
            image: gilletteLogo,
            title: "gillette"
        },
        {
            id: 11,
            image: smileLogo,
            title: "smile"
        },
        {
            id: 12,
            image: arielLogo,
            title: "ariel"
        },
        {
            id: 13,
            image: samsungLogo,
            title: "samsung"
        },
        {
            id: 14,
            image: pzLogo,
            title: "pz"
        },
        {
            id: 15,
            image: interswitchLogo,
            title: "interswitch"
        },
        {
            id: 16,
            image: hennesyLogo,
            title: "hennesy"
        },
        {
            id: 17,
            image: wrigleyLogo,
            title: "wrigley"
        },
        {
            id: 18,
            image: unileverLogo,
            title: "unilever"
        },
        {
            id: 19,
            image: ubaLogo,
            title: "uba"
        },
        {
            id: 20,
            image: bbcLogo,
            title: "bbc"
        }
    ];

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: numOfSlideToShow,
        slidesToScroll: 1,
        centerPadding: "60px",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    useEffect(()=>{
        window.addEventListener('resize', handleResize);
        handleResize();
      }, []);
    
    return (
        <div className="landing-page-about" id="about">
             <div className="about-sponsors">
                <div className="about-sponsors-header">
                    <h1>Trusted by Agencies & Popular brands</h1>
                </div>
                <div className="about-sponsors-text">
                    <img src={redBackgroundDot} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                    
                        <div className="about-sponsors-text-container2">
                        <div className="about-sponsors-inner-text-container">
                        <p className=" text-xs sm:text-xs md:text-xl">We are committed to helping you build meaningful interaction and topnotch digital brand communications with customers everywhere and everytime.</p>
                        </div>
                        </div>
                        <div className="about-sponsors-text-container3">
                        <div className="about-sponsors-inner-text-container">
                        <p className="text-xs sm:text-xs md:text-xl">We are committed to helping you build meaningful interaction and topnotch digital brand communications with customers everywhere and everytime.</p>
                        </div>
                        </div>
                        <div className="about-sponsors-text-container4">
                        <div className="about-sponsors-inner-text-container">
                        <p className="text-xs sm:text-xs md:text-xl">We are committed to helping you build meaningful interaction and topnotch digital brand communications with customers everywhere and everytime.</p>
                        </div>
                        </div> 
                        
                </div>
               
                <div className="brand-container">
               
                    <div className="inner-brand-container">
                    <Slider {...settings}>
                        {brandData.map((brand, index)=>{
                            return(
                                <div div className="brand-wrapper">
                                    <div className="brand-inner-wrapper">
                                        <img src={brand.image} alt="Digital out-of-home Advertising Agency in Nigeria" className='object-contain transform hover:scale-110 cursor-pointer'></img>
                                    </div>
                                </div>
                            )
                        })}
                        </Slider>
                    </div>
                </div>
               
            </div> 
            <div className="about-team" >
                <div className='background-overlay'>
                </div>
                    <div className="about-team-header">
                        <h1>Our Team</h1>
                        <div>
                            <p>We are a team of experts working collaboratively to empower you with opportunities to reach your customers and grow your bottom line</p>
                        </div>
                    </div>
                    <div className="team-image-wrapper">
                        <div className="imageBackground">
                            <img className="team-image" src={teamImage} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                        </div>
                        <img className="circle1" src={smallCircle} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                        <img className="circle2" src={smallestCircle} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                        <img className="circle3" src={smallCircle} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                        <img className="red-dots" src={redDots} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                        <img className="yellow-dots" src={smallRectangle} alt="Digital out-of-home Advertising Agency in Nigeria"/>
                    </div>
                    <Link to="/team-profile" className='relative z-10'>
                        <button className="about-team-btn border-2 hover:bg-white hover:text-charity-color2 hover:border-charity-color2">View Full Profile</button>
                    </Link>
                
            </div>
        </div>
    )
}

export default LandingPageAbout;
