import React from 'react'
import "./AboutTemplate.css";
import { useHistory, Link } from "react-router-dom";
import parse from 'html-react-parser';

function AboutTemplate({title, contentData, imageData, _id, websiteURL}) {

    const history = useHistory();
    let listKeys = Object.keys(contentData.list);
    const handleIconOnClick = (id) =>{
        history.push(`/gallery/${id}`);
    }
    return (
        <>
        <div className="w-85 mx-auto py-12">
                <div className="text-lg">
                    <h3 className="about-header-alignment text-brand-color1 opacity-80 font-bold text-xl cursor-pointer">{title}</h3>
                    <div className="w-full about-template-container">
                        <div className="one lg:w-9/12 w-full about-text-wrapper">
                            {
                                contentData.para.map((data,id) =>{
                                    return(
                                        <>
                                            <p key={id} className="lg:w-9/12 w-full text-left">{parse(data)}</p>
                                        </>
                                    )
                                })
                            }
                            <h3 className='text-brand-color1 opacity-80 font-bold text-xl pt-4'>{contentData.listHeader}:</h3>
                            <ul>
                            {
                                listKeys.map((key, index) =>{
                                    return(
                                        <li key={index} className="list-disc pl-3"><b>{key}:</b> {contentData.list[key]}</li>
                                    )
                                })
                                        
                                  
                            }
                            </ul>
                        </div>
                        <div className="text-brand-color1 w-full two lg:block flex lg:justify-center lg:items-center justify-evenly lg:m-0 lg:w-1/4 ">
                        {
                            imageData.map((data,id) =>{
                                return(
                                    <div key={id} className="text-center my-4 cursor-pointer opportunity-icon-wrapper" onClick={handleIconOnClick.bind(null, data.path)}>
                                        <img src={data.img} alt="Outdoor Advertising Agency in Lagos, Nigeria" className="w-12 h-7 object-contain" />
                                        <p className="font-bold text-xxs md:text-lg">{data.imgTitle}</p>
                                    </div>
                                )
                                
                            })
                        }
                        </div>
                    </div>
            </div>
        </div>
        <hr className="border-b-4 border-brand-color3 w-11/12 m-auto"/>
        </>
    )
}

export default AboutTemplate;
