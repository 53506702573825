import React from 'react'
import "./Signin.css";
import logo from "../../assets/images/NIMBUS_WHITE_LOGO.png";
import backgroundDots from "../../assets/images/smallRectangle.png";
import {Link} from "react-router-dom";
function PasswordReset() {
    return (
        <div className="signin-container">
            <div className="signin-header">
                <div>
                    <img className="signin-header-logo" src={logo} alt="nimbus logo"/>
                    <img src={backgroundDots} alt=""  className="signin-yellow-dots"/>
                </div>
            </div>
            <div className="signin-body">
                <img src={backgroundDots} alt="" className="signin-yellow-dots"/>
                <div className="signin-inner-body">
                    <div className="signin-backgroundImg">

                    </div>
                    <div className="signin-backgroundCircle">
                    
                    </div>
                    <div className="signin-form-container">
                        <form className="signin-form">
                            <div className="signin-input-wrapper">
                                <p>Request Password Reset</p>
                                <input type="email" placeholder="Enter your email address" className="siginup-input"/>
                                <button>Request reset</button>
                            </div>
                            
                        </form>
                    </div>
                    <div className="signin-bottom-text">
                        <p>Remember your password? <Link to="/signin">Login</Link></p>
                    </div>
                </div>
            </div>
            <div className="signin-footer">
                <button>Contact us</button>
                <img src={backgroundDots} alt="" className="signin-yellow-dots" />
            </div>
        </div>
    )
}

export default PasswordReset
