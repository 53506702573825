import vision from "../../assets/images/aboutIcons/visionIcon.png"
import mission from "../../assets/images/aboutIcons/missionIcon.png";
import goals from "../../assets/images/aboutIcons/goalIcon.png";
import teamwork from "../../assets/images/aboutIcons/teamworkIcon.png";

export const Data = [
    {
        images: mission,
        title:"Innovation",
    },
    {
        images:teamwork,
        title:"Integrity"
    },
    {
        images: goals,
        title:"Excellence"
    },
    {
        images: vision,
        title:"Value Creation"
    }
]