import React, {useState, useContext} from 'react'
import "./AddTeam.css";
import { MdClose } from "react-icons/md";
import { TeamProfileContext } from '../../Utils/Context/teamProfileContext';
import Loader from '../Loader/Loader';

function UpdateTeam(props) {
    const { isLoading, rankMessage, editProfile } = useContext(TeamProfileContext);
    const [ image, setImage ] = useState(props.image);
    const [ imageIsSet, setImageIsSet ] = useState(false);
    const [ teamProfile, setTeamProfile ] = useState({
        bio:props.bioValue,
        name: props.nameValue,
        position: props.positionValue,
    });
    const [ file, setFile ] = useState(props.image);

    const handleFormOnSubmit = (event) =>{
        event.preventDefault();
        console.log(teamProfile);
        if(imageIsSet){
            const formData = new FormData();
            formData.append('photo',file);
            formData.append('name',teamProfile.name);
            formData.append('bio',teamProfile.bio);
            formData.append('position',teamProfile.position);
            console.log("success");
            editProfile(props.id,formData);
        }
        else{
            editProfile(props.id,teamProfile);
        }
    }
    const handleInputOnChange = (event) =>{
        if(event.target.name !== "photo"){
            setTeamProfile((prevData) =>{
                return { ...prevData, [event.target.name] : event.target.value }
            })
        }
        else{
            if(event.target.files && event.target.files[0]){
                setFile(event.target.files[0])
                const reader = new FileReader();
                reader.onload = (e) =>{
                    setImageIsSet(true);
                    setImage(e.target.result);
                }
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    }
    const handleOnDragOver = (event) =>{
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <div className="team-bio">
            {
                isLoading && <Loader color="#FFFFFF" />
            }
            <div className="team-bio-header">
                <i onClick={props.onClick}>{<MdClose />}</i>
            </div>
            <form onSubmit={handleFormOnSubmit} className="add-team-form">
            {
                rankMessage.message &&
                <p className={`${rankMessage.status === "0" ? "bg-red-300 text-red-600 py-2 my-2": ""} ${rankMessage.status === "1" ? "bg-green-200 text-green-600 py-2 my-2": ""} pl-6`}>{rankMessage.message}</p>
            }
                <div className="team-bio-content-contianer">
                    <div className="add-team-img">
                        <img src={image} alt="" className='object-cover'/>
                        <label htmlFor="profile-img">Select Image</label>
                        <input id="profile-img" name="photo" type="file" accept="image/*" style={{display:"none"}} onChange={handleInputOnChange} onDragOver={handleOnDragOver} onDrop={handleInputOnChange}/>
                    </div>
                    <div className="add-team-content">
                        <div>
                            <label>Name:</label>
                            <input type="text" name="name" placeholder="John Doe" defaultValue={props.nameValue} onChange={handleInputOnChange} required/>
                            <label>Position:</label>
                            <select name="position" onChange={handleInputOnChange} required>
                                <option disabled selected>Position</option>
                                {
                                    props.positionValue.map((positions) =>{
                                        return(
                                            <option>{positions.position}</option>
                                        )
                                    }) 
                                }
                            </select>
                            <label>Bio:</label>
                            <textarea name="bio" placeholder="Bio" rows="7" defaultValue={props.bioValue} onChange={handleInputOnChange} required/>
                        </div>
                    </div>
                </div>
                <div className="add-team-footer">
                    <button onClick={props.onClick}>Close</button>
                    <button  type="submit">Add</button>
                </div>
            </form>
        </div>
    )
}

export default UpdateTeam;
