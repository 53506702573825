import React,{useState, useContext,useEffect, useRef} from 'react'
import { Link } from "react-scroll"
import { Link as LINK } from "react-router-dom";
import logo from "../../assets/images/NIMBUS_OFFICIAL_LOGO.png"
import backgroundImage from "../../assets/images/nonimateCharityBackground.png";
import NominateInput from './NominateInput';
import SelectField from './SelectField';
import Loader from '../Loader';
import axios from 'axios';
import { CharityContext } from '../../Utils/Context/CharityContext';


function NominateCharity(props) {
    const { countDownDetails, topNominees } = useContext(CharityContext);
    const myRef = useRef(null);
    const [ loading, setLoading ] = useState(false);
    const [ votingDetails, setVotingDetails ] = useState({
        name:"",
        charityName: "",
        email: ""
    })
    const [ message, setMessage ] = useState({
        msg: "",
        status: 0
    });
    const updateMessage = (msg, status) =>{
        executeScroll();
        setMessage({
            msg: msg,
            status: status
        });
        setTimeout(() => {
            setMessage({
                msg: "",
                status: 0
            });
        }, 7000);
    }
    const handleOnSubmitRound = (e) =>{
        e.preventDefault();
        setLoading(true);
        axios.post(`https://nimbus-media-staging.herokuapp.com/api/v1/charity?round=${countDownDetails.round}`, votingDetails)
        .then((res) =>{
            setLoading(false);
            setVotingDetails({
                name:"",
                charityName: "",
                email: ""
            })
            updateMessage("Proceed to your Email to verify your vote !!!", 2);
        
        })
        .catch(err =>{
            updateMessage(err.response.data.message + "!!!", 1);
            setLoading(false);
        })
    }
    
    const handleOnChange = (key, value) =>{
        setVotingDetails((prevState) =>{
            return{
             ...prevState,
             [key]: value
            } 

         })
    }
    
    const handleScrollToView = () =>{
        props.handleScroll(myRef);
    }
    const executeScroll = () => {
        myRef.current.scrollIntoView();
    }  
    useEffect(()=>{
        window.scrollTo(0, 0);
        handleScrollToView();
      }, []);

    return (
        <div className="w-full">
            <div className="bg-charity-color1">
                <div className="w-full m-auto lg:w-11/12 sm:w-full flex items-center justify-evenly">
                    <div className="">
                        <LINK to="/">
                            <img src={logo} className="w-24 h-20 object-cover" alt="img" />
                        </LINK>
                    </div>
                    <div className="">
                        <h1 className="md:text-3xl text-xl text-brand-color1 font-bold">Nominate a charity</h1>
                    </div>
                </div>
            </div>
            <div>
                <div className="relative h-screen">
                    <img src={backgroundImage} className="w-full h-full object-cover" alt="background_img" />
                </div>
                <div className="absolute bottom-1/4 lg:left-1/4 left-20 w-6/12 m-auto">
                    <div className="md:w-80 w-60">
                        <h1 className="font-black text-charity-color2 md:text-5xl text-4xl mb-4">NOMINATE A CHARITY</h1>
                        <Link className="bg-charity-color1 font-bold text-sm py-3 px-7 rounded-full no-underline text-brand-color1 cursor-pointer" to="charity-form" spy={true} smooth={true} offset={50} duration={500}>NOMINATE NOW</Link>
                    </div>
                </div>
            </div>
            <div className="bg-charity-color3 pt-16 pb-28 relative" ref={myRef} id="charity-form">
                { props.children}
                <div className="lg:w-2/4 md:w-3/4 sm:w-4/5 w-4/5 m-auto">
                    <p className="text-charity-color5 ml-1 pb-3"><span className="mr-1">*</span>Required</p>
                    <p className={`${message.status === 1 ? "bg-red-300 text-red-600 py-2 mb-2 mt-10": ""} ${message.status === 2 ? "bg-green-200 text-green-600 py-2 mb-2 mt-10": ""} pl-6`}>{message.msg}</p>
                    <form onSubmit={handleOnSubmitRound}>
                    <NominateInput type="text" name="name" handleChange={handleOnChange} value={votingDetails.name} label="Name" placeholder="Your Name" />
                    <NominateInput type="email" name="email" handleChange={handleOnChange} value={votingDetails.email} label="Email" placeholder="Your Email"/>
                    {
                        countDownDetails.round === 2 ?
                        <SelectField name="charityName" handleChange={handleOnChange} value={votingDetails.charityName} label="Charity Name" options={topNominees} />
                        :
                        <NominateInput type="text" name="charityName" handleChange={handleOnChange} value={votingDetails.charityName} label="Charity Name" placeholder="Charity Name"/>
                    }
                    <button type="submit" className={`bg-charity-color1 rounded-full py-2 md:w-2/12 sm:w-full w-full font-bold text-base text-brand-color1 ${loading ? "opacity-30" : "opacity-100"}`} disabled={loading}> 
                    {
                        loading &&
                        <Loader color="dark" />
                    }
                    SUBMIT
                    </button>
                </form>
                </div>
            </div>
        </div>
    )
}

export default NominateCharity
