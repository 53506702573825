import React,{useEffect,useState} from 'react'
import Header from '../Components/Header/Header'
import BlogPost from '../Components/BlogPost/Blog';
import Loader from '../Components/Loader/Loader';
import Footer from '../Components/Footer/Footer';
import Axios from "axios";
import GetStartedBtn from '../Components/GetStartedBtn';
function Blog(props) {

    const [ posts, setPosts ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        Axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nimbusmedia")
        .then(res =>{
            setLoading(false);
            setPosts(res.data.items);
            
        })
        .catch(err =>{
            setLoading(false);
        })
     }, [])
    return (
        <>
            <Header />
            <div className="body-wrapper relative">
            <div className="blog">
                    <BlogPost  posts={posts} isLoading={loading}>
                        <Loader color="#FFFFFF"/>
                    </BlogPost>
                    <Footer footerBodyVisible={true} footerHeadVisible={true} />
                </div>
                <GetStartedBtn />
            </div>
        </>
    )
}

export default Blog
