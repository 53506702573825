import React, {useState, useEffect, useContext} from 'react'
import SectionHeader from '../Charity/SectionHeader';
import InputField from "../Ranking/InputField";
import {TeamProfileContext} from "../../Utils/Context/teamProfileContext";
import Loader from '../Loader';

function CreateRank() {
    const [ rankDetails, setRankDetails ] = useState({
        priority: "",
        position: ""
    });
    // const [ formMessage, setFormMessage ] = useState("")
    const { isLoading, rankMessage, updateRankMessage, createTeamPositions, deleteTeamPositions } = useContext(TeamProfileContext);

    const validateInputs = (data) =>{
        if(data.priority === "" || data.priority <= 0){
            return false;
        }
        else if(data.position === ""){
            return false;
        }
        return true;
    }
    const handleOnChange = (key, value) =>{
        setRankDetails((prevState) =>{
            return{ 
                ...prevState,
                [key]: value
            }
        });
    }

    const handleOnSubmit = (e) =>{
        const inputStatus = validateInputs(rankDetails);
        if(inputStatus){
            createTeamPositions(rankDetails);
        }
        else{
            updateRankMessage("Fields are required", "0");
        }
        setRankDetails({
            priority: "",
            position: ""
        })
        e.preventDefault();
    }
    return (
        <div className="w-full pt-4 pb-5">
            <SectionHeader headerText="Create Rank" />
                <div className="w-4/5 m-auto">
                <form onSubmit={handleOnSubmit}>
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <p className={`${rankMessage.status === "0" ? "bg-red-300 text-red-600 py-2 my-2": ""} ${rankMessage.status === "1" ? "bg-green-200 text-green-600 py-2 my-2": ""} pl-6`}>{rankMessage.message}</p>
                            <InputField type="number" name="priority" label="Position Number" width="1/3" handleOnChange={handleOnChange} value={rankDetails.priority}/>
                            <InputField type="text" name="position" label="Position Name" width="1/3" handleOnChange={handleOnChange} value={rankDetails.position}/>
                        </div>
                    </div>
                    <div className="py-10">
                        <button className="w-32 py-2 rounded-full bg-charity-color8 border-2 border-brand-color1 text-white font-semibold text-lg">
                        {
                            isLoading &&
                            <Loader color="light"/>
                        }
                        Add
                        </button>
                    </div>
                </form>
                </div>
        </div>
    )
}

export default CreateRank;
