import React from 'react'
import slide1 from "../../assets/images/Slides/slide7.png";
import slide2 from "../../assets/images/Slides/slide5.png";
import slide3 from "../../assets/images/Slides/slide6.png";
import slide4 from "../../assets/images/Slides/slide8.png";
import Carousel from 'react-bootstrap/Carousel';

function Slider({ image }) {
  const Data = [
    slide1,
    slide2,
    slide3,
    slide4
  ]
    return (
        <Carousel controls={false} indicators={false} className="landing-page-height" fade="true">
        {
            Data.map((data, index) =>{
                return(
                  <Carousel.Item  className="landing-page-height">
                    <div className="w-full h-full bg-black opacity-40 absolute"></div>
                    <img
                      className="w-full h-full object-cover"
                      src={data}
                      alt="Digital out-of-home Advertising Agency in Nigeria"
                    />
                  </Carousel.Item>
                )
            })
        }
        </Carousel>
      
    )
}

export default Slider
