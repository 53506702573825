import React, { useState, useEffect } from "react";
import logo from "../../assets/images/NIMBUS_OFFICIAL_LOGO.png";
import "./Header.css";
import { IoMdArrowDropdown, IoMdMenu } from "react-icons/io";
import { useLocation, Link as LINK } from "react-router-dom";


const Header = () =>{
    const [ dropDownStatusStyle, setDropDownStatusStyle ] = useState("dropDownIsNotActive");
    const [ dropDownStatus, setDropDownStatus ] = useState(false);
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");

    const [ isMenuActive , setIsMenuActive ] = useState(false);
    const [ headerStyle, setHeaderStyle] = useState({
        background: "#E5EFFF",
        padding: window.innerWidth <= 760 ? "0px 10px": "10px"
    })

    const MenuState = () =>{
       setIsMenuActive(!isMenuActive);
    }
    
    const handleOnScroll = () =>{
        if(window.scrollY > 0 && window.innerWidth >= 760){
            setHeaderStyle((prevState) =>{
                return {...prevState, padding: "0px"}
            })
        }
        else{
            setHeaderStyle((prevState) =>{
                return {...prevState, padding: "0px 10px"}
            })
        }
    }
    const handlePopUpOnOver = () =>{
        if(window.innerWidth > 760){
            setDropDownStatusStyle("dropDownIsActive");
        }
    }

    const handlePopUpOnOut = () =>{
        if(window.innerWidth > 760){
            setDropDownStatusStyle("dropDownIsNotActive");
        }
    }
    const handleDropdown = () =>{
        if(window.innerWidth <= 760){
            if(dropDownStatus){
                console.log(dropDownStatus)
                setDropDownStatusStyle("dropDownIsNotActive");
                setDropDownStatus(false);
            }
            else{
                console.log(dropDownStatus)
                setDropDownStatusStyle("dropDownIsActive");
                setDropDownStatus(true);
            }
            
        }

    }
   
    useEffect(()=>{
        window.addEventListener('scroll', handleOnScroll);
        return(() =>{
            window.removeEventListener('scroll', handleOnScroll);
        })
    }, [])

    return(
        <div className="flex justify-evenly items-center sticky top-0 left-0 w-full z-60 h-24" style={headerStyle}>
            <div className="logo-wrapper">
                <LINK to="/home">
                    <img src={logo} className="logo-wrapper-img" alt="Digital out-of-home Advertising Agency in Nigeria" /> 
                </LINK>
                <i onClick={MenuState} className={isMenuActive ? "icon activeNav"  : "icon"}>{<IoMdMenu />}</i>
            </div>
            <div className="side-nav-backdrop" style={{display: isMenuActive ? "block" : "none"}} onClick={MenuState}>
            </div>
            <div className= {isMenuActive ? "nav-wrapper activeNav"  : "nav-wrapper"}>
                <ul className="header-nav-ul">
                    <li>
                        <LINK className={splitLocation[1] === "" || splitLocation[1] === "home" ? "active" : "inactive"} to="/home">Home</LINK>
                    </li>
                    <li className="about-header-nav">
                        <LINK to={`${pathname}`} className={(splitLocation[1] === "about") || (splitLocation[1] === "team-profile") ? "active" : ""} onMouseLeave={handlePopUpOnOut} onMouseEnter={handlePopUpOnOver} onClick={handleDropdown}>About Us <span>{<IoMdArrowDropdown />}</span></LINK> 
                        <div className={`about-dropdown-container ${dropDownStatusStyle}`}>
                            <div className="dropdown-content">
                                <p>
                                <LINK to="/about" className={splitLocation[1] === "about" ? "active" : ""}>Our Network</LINK>
                                </p>
                            </div>
                            <hr />
                            <div className="dropdown-content">
                                <p>
                                <LINK to="/team-profile" className={splitLocation[1] === "team-profile" ? "active" : ""}>Full Team Profile</LINK>
                                </p>
                            </div>
                        </div>
                    </li> 
                    <li>
                        <LINK className={splitLocation[1] === "gallery" ? "active" : ""} to="/gallery">Gallery</LINK>
                    </li> 
                    <li>
                        <LINK className={splitLocation[1] === "blog" ? "active" : ""} to="/blog">Blog</LINK>
                    </li>
                    <li>
                        <LINK className={splitLocation[1] === "services" ? "active" : ""} to="/services">Services</LINK>
                    </li>
                    <li>
                        <LINK className={splitLocation[1] === "contact-us" ? "active" : ""} to="/contact-us">Contact Us</LINK>
                    </li> 
                    {/* <li className="side-nav-signin-wrapper">
                        <NavLink activeClassName="" className="sign-in" to="/signin"><span>Sign in</span></NavLink>
                    </li> */}
                </ul>
            </div>
            {/* <div className="signin-wrapper">
                <NavLink activeClassName="" className="sign-in" to="/signin">Sign in</NavLink>
            </div> */}
        </div>
    )
}

export default Header;