import React,{useState,useEffect, useContext} from 'react'
import gallery1 from "../../assets/images/gallery1.png";
import gallery2 from "../../assets/images/gallery2.png";
import gallery3 from "../../assets/images/gallery3.png";
import galleryImg1 from "../../assets/images/galleryImg1.png";
import galleryImg2 from "../../assets/images/galleryImg2.png";
import galleryImg3 from "../../assets/images/galleryImg3.png";
import SkeletonGallery from "../Skeletons/SkeletonGallery";
import { CgArrowLongLeft,CgArrowLongRight } from "react-icons/cg";
import  { Link } from "react-router-dom";
import "./LandingPageGallery.css";
import { GalleryContext } from '../../Utils/Context/galleryContext';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



function LandingPageGallery({ gallery }) {
 
    const [numOfSlideToShow,setNumOfSlideToShow] = useState(3);
    const [ centerModeStatus, setCenterModeStatus ] = useState(true);
    const [ imageIndex, setImageIndex ] = useState(0);

      const handleResize = () => {
        if (window.innerWidth <= 530) {
          setNumOfSlideToShow(1);
          setCenterModeStatus(false);
        }
        else if (window.innerWidth <= 1024) {
          setNumOfSlideToShow(1);
          setCenterModeStatus(false);
        }
        else if (window.innerWidth <= 1240) {
          setNumOfSlideToShow(1);
          setCenterModeStatus(true);
        }
        else {
            setNumOfSlideToShow(3);
        }
      }

      function SampleNextArrow(props) {
        const { onClick } = props;
        return (
        <div className="forward-img-nav cursor-pointer">
            <i className="landing-page-image-nav" onClick={onClick}>{<CgArrowLongRight />}</i>
        </div>
        );
      }
      
      function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div className="back-img-nav cursor-pointer">
                <i className="landing-page-image-nav" onClick={onClick}>{<CgArrowLongLeft />}</i>
            </div>
        );
      }

      const settings = {
        className: "center",
        dots: false,
        infinite: true,
        slidesToShow: numOfSlideToShow,
        slidesToScroll: 1,
        centerPadding: "60px",
        centerMode: centerModeStatus,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
      };


      useEffect(()=>{
        window.addEventListener('resize', handleResize);
        handleResize();
      }, []);
    
    
      return (
        <>
          <div className="gallery-container">
              <h1 className="gallery-header text-2xl md:text-4xl">Our Gallery</h1>
              
              <Slider {...settings}>
              
                {
                  gallery.length === 0 &&
                    [1,2,3,4].map((n,index) =>{ 
                      return( 
                        <div id={index} key={n} className={(index === imageIndex) ? "gallery-slider gallery-slider-active" : "gallery-slider"}>
                            <SkeletonGallery theme="light"/>
                        </div>
                    )})
                }
                { 
                  gallery.length !== 0 &&
                  gallery.map((image, index) =>{
                    return(
                      index < 5 &&  
                      <div id={index} className={(index === imageIndex) ? "gallery-slider gallery-slider-active" : "gallery-slider"}>
                        <img className="gallery-image object-cover" src={image.imageUrl} alt="img"/>
                      </div>
                    )
                  })
                }
              </Slider>
          </div>
          <div className="text-center py-5">
            <Link to="/gallery" className="">
              <button className="about-team-btn border-2 hover:bg-white hover:text-charity-color2 hover:border-charity-color2">View More...</button>
            </Link>
          </div>
        </>
      );
      }
   

export default LandingPageGallery;