import React,{useContext,useEffect, useState} from 'react';
import DropdownField from './DropdownField';
import SectionHeader from './SectionHeader';
import InputField from '../Ranking/InputField';
import { TeamProfileContext } from '../../Utils/Context/teamProfileContext';
import axios from "axios";
import Loader from "../Loader/Loader";


function TimerSection({ handleLoadingState, handleModal }) {
    const countDownId = localStorage.getItem("countDownId");
    const [ loading, setLoading ] = useState(false);
    const { countDownDetails } = useContext(TeamProfileContext);
    const [ countDownMessage, setCountDownMessage ] = useState({
        message: "",
        status: ""
    });

    const [ countDownDetail, setCountDownDetail ] = useState({
        round: null,
        start: "",
        stop: ""
    });
    const handleOnChange = (name, value) =>{
        setCountDownDetail((prevState) =>{
            return{
                ...prevState, 
                [name]: value
            }
        })
    }
    const handleOnDelete = (e) =>{
        e.preventDefault();
        deleteCountDownConfiguration(countDownId);
        setCountDownDetail({
            round: null,
            start: "",
            stop: ""
        })
    }
    const handleOnSubmit = (e) =>{
        e.preventDefault();
        createCountDownConfiguration(countDownDetail);
    }

    const deleteCountDownConfiguration = (id) =>{
        handleLoadingState(true);
        axios.delete(`https://nimbus-media-staging.herokuapp.com/api/v1/date/${id}`, {withCredentials: true})
        .then((res) =>{
            localStorage.removeItem("round");
            localStorage.removeItem("countDownId");
            getCountDownConfiguration();
            handleLoadingState(false);
            handleModal("Countdown has been deleted !!","1");
            // updateCountDownMessage("Countdown has been deleted !!","1")
            setCountDownDetail((prevState) =>{
                return{
                    round: null,
                    start: "",
                    stop: ""
                }
            })
           
           
        })
        .catch(err =>{
            handleLoadingState(false);
            handleModal(err.response.data.message,"0");
            // updateCountDownMessage(err.response.data.message,"0")
        })
    }
    const createCountDownConfiguration = (paylod) =>{
        handleLoadingState(true)
        axios.post("https://nimbus-media-staging.herokuapp.com/api/v1/date", paylod,{withCredentials: true})
        .then((res) =>{
            if(res.data.status === "success"){
                if(res.data.data.entry.length !== 0){
                    localStorage.setItem("round",paylod.round);
                    localStorage.setItem("countDownId",res.data.data.entry._id);
                    getCountDownConfiguration();
                    handleLoadingState(false);
                    handleModal("Countdown has been set !!", "1");
                    // updateCountDownMessage("","1")

                    // setCountDownDetailsId(res.data.data.entry._id);
                    // setCountDownDetails((prevState) =>{
                    //     return{
                    //         ...prevState,
                    //         round: res.data.data.entry.round,
                    //         start: res.data.data.entry.start,
                    //         stop: res.data.data.entry.stop
                    //     }
                    // })
                }
            }
        })
        .catch((err) =>{
            handleLoadingState(false);
            handleModal(err.response.data.message,"0");
            // updateCountDownMessage(err.response.data.message,"0")
        })
    }
    const getCountDownConfiguration = () =>{
        handleLoadingState(true);
        axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/date")
        .then((res) =>{
            if(res.data.status === "success"){
                handleLoadingState(false);
                if(res.data.data.entry.length === 0){
                    setCountDownDetail((prevState) =>{
                        return{
                            round: null,
                            start: "",
                            stop: ""
                        }
                    })
                }
                else{
                    localStorage.setItem("countDownId",res.data.data.entry[0]._id );
                    console.log(res.data.data.entry[0].start);
                    const details = {
                            round: res.data.data.entry[0].round,
                            start: res.data.data.entry[0].start,
                            stop: res.data.data.entry[0].stop
                    }
                    setCountDownDetail((prevState) =>{
                        return{
                            ...details
                        }
                    })
                }
            }
            
        })
        .catch(err =>{
            handleLoadingState(false);
        })
    }
    const updateCountDownMessage = (message, status) => {
        setCountDownMessage({
            message: message,
            status: status
        });
        setTimeout(() => {
            setCountDownMessage({
                message: "",
                status: ""
            });
        }, 5000);
    }
    useEffect(() => {
    //    setCountDownDetail({
    //     round: countDownDetails.round,
    //     start: countDownDetails.start,
    //     stop: countDownDetails.stop
    //    })
    getCountDownConfiguration();
    }, [])
    return (
        <>
         <div className="w-full pt-4 pb-5">
            <SectionHeader headerText="Set timer" />
                <div className="w-4/5 m-auto">
                <form onSubmit={handleOnSubmit}>
                <p className={`${countDownMessage.status === "0" ? "bg-red-300 text-red-600 py-2 my-2": ""} ${countDownMessage.status === "1" ? "bg-green-200 text-green-600 py-2 my-2": ""} pl-6`}>{countDownMessage.message}</p>
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <DropdownField  label="round" name="round" options={[1,2]} width="1/3" handleOnChange={handleOnChange} defaultValue={countDownDetail.round}/>
                        </div>
                        <div>
                            <InputField type="datetime-local" name="start" label="Start date" width="2/4" handleOnChange={handleOnChange} value={countDownDetail.start}/>
                        </div>
                        <div>
                            <InputField type="datetime-local" name="stop" label="End date" width="2/4" handleOnChange={handleOnChange} value={countDownDetail.stop}/>
                        </div>
                    </div>
                    <div className="py-10 flex">
                    {
                        countDownId === null &&
                        <button type="submit" className="py-2 px-6 rounded-full border-2 border-brand-color1 text-brand-color1 hover:bg-charity-color8 hover:text-white font-semibold text-lg mr-20" disabled={loading}>Publish</button>
                    }
                        
                        {
                            countDownId !== null &&
                            <button type="button" onClick={handleOnDelete} className="w-32 py-2 rounded-full border-2 border-charity-color5 text-charity-color5 hover:bg-charity-color5 hover:text-white font-semibold text-lg">Delete Timer</button>
                        }
                    </div>
                </form>
                </div>
            </div>
        </>
        
    )
}

export default TimerSection
