import React from 'react'
import AdminHeader from '../Components/Header/AdminHeader'
import Ranking from '../Components/Ranking/Ranking'
import Footer from '../Components/Footer/Footer'

function RankingPage() {
    return (
        <div className="body-wrapper">
            <AdminHeader />
            <Ranking />
            <Footer footerBodyVisible={false} footerHeadVisible={true} posts={[]} blogFooterVisible={false}/>      
        </div>
    )
}

export default RankingPage
