import React from 'react'
import smallCircle from "../../assets/images/smallCircle.png";
import aboutImage2 from "../../assets/images/aboutImage2.png";
import bigCircle from "../../assets/images/bigCircle.png";
import verticalDots from "../../assets/images/verticalDots.png";
import horizontalDots from "../../assets/images/horizontalDots.png";
import ReactPlayer from 'react-player'
import { useInView } from 'react-intersection-observer';
import "../LandingPageAbout/LandingPageAbout.css";

function AboutUs() {

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
      });

  return (
    <div className="landing-page-inner-about">
        <div className="landing-page-about-body" ref={ref}>
            <div className={`${inView ? "about1-zoom" : "about1"}`}>
                <div className="about-image1">
                    <img className="small-circle" src={smallCircle} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    <img className="big-circle" src={bigCircle} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    <img className="vertical-dots" src={verticalDots} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    {/* <img className="image1" src={aboutImage1} alt="Digital out-of-home Advertising Agency in Nigeria"></img> */}
                    <div className="image1 rounded-xl overflow-hidden mb-6">
                        <ReactPlayer width="100%" height="100%" controls url='https://www.youtube.com/watch?v=0Eo7S7PCWuI' style={{borderRadius: "30px"}} className="rounded-3xl"/>
                    </div>
                </div>
                <div className="about-content1">
                    <div className="inner-about-content1">
                        <h1>WHAT WE DO</h1>
                        <p>All-round out-of-home advertising solutions which include branding, brand activation, consultancy and OOH advertising on billboard, lampost, shopping malls, superstores, street, road shows, digital OOH media, etc. </p>
                    </div>
                </div>
            </div>
            <div className={`${inView ? "about2-zoom" : "about2"}`}>
            
                <div className="about-image2" id="about-image2">
                    <img className="small-circle2" src={smallCircle} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    <img className="big-circle2" src={bigCircle} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    <img className="horizontal-dots" src={horizontalDots} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                    <img className="image2" src={aboutImage2} alt="Digital out-of-home Advertising Agency in Nigeria"></img>
                </div>
                <div className="about-content2" id="about-content2">
                    <div className="inner-about-content2">
                        <h1>WHY WORK WITH US</h1>
                        <p>We are the leading digital out-of-home advertising agency in Nigeria with a network of outdoor advertising media nationwide and portfolio of projects for customers in all sectors across traditional OOH advertising platforms.</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div> 
  )
}

export default AboutUs