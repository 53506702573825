import React from "react";
import "./Footer.css";
import logo from "../../assets/images/NIMBUS_WHITE_LOGO.png";
import { HiMail } from "react-icons/hi";
import { FaFacebook } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import { GrLinkedin } from "react-icons/gr";
import { BsCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineTwitter,AiFillInstagram } from "react-icons/ai";
import BlogFooter from "./BlogFooter";

const socialHandles = [
    {
        name: "",
        icon: "",
        href:""
    }
]

const Footer = (props) =>{
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return(
        <div className="footer-container">
            <div className="footer-head-container" style={{display: props.footerHeadVisible ? "block" :"none" }}>
                <div className="footer-head-inner-container">
                    <div className="footer-head-text">
                        <p>Would you love to explore our advertising possibilities?</p>
                    </div>
                    <div className="contact-wrapper">
                        <Link to="/contact-us" className="contact-wrapper-link">Contact us</Link>
                    </div>
                    <i>{<BsCircle />}</i>
                </div>
               
            </div>
            {/* <BlogFooter blogFooterVisible={props.blogFooterVisible} posts={props.posts}/> */}
            <div className="footer-body" style={{display: props.footerBodyVisible ? "block" :"none" }}>
                <div>
                    <img src={logo} alt="img" className="object-cover footer-img"/>
                    {/* <p>We expose your brand on high-quality displays whether on digital and static platforms in high traffic shopping malls across Nigeria.</p>
                    <hr /> */}
                    <div className="footer-icon-wrapper order1">
                    {/* <Link to="https://twitter.com/NimbusMediaNG" passHref target={"_blank"}>
                        <i>{<AiOutlineTwitter />}</i>
                    </Link> */}
                    <i  onClick={openInNewTab.bind(null, "https://twitter.com/NimbusMediaNG")}>{<AiOutlineTwitter />}</i>
                    <i  onClick={openInNewTab.bind(null, "https://www.facebook.com/nimbusNG/")}>{<FaFacebook />}</i>
                    <i  onClick={openInNewTab.bind(null, "https://www.instagram.com/nimbusmediang/")}>{<AiFillInstagram />}</i>
                    <i  onClick={openInNewTab.bind(null, "https://www.linkedin.com/company/nimbus-media-limited/")}>{<GrLinkedin />}</i>
                </div>
                </div>
                <div className="footer-contact">
                    <div className="">
                        <i>{<HiMail />}</i>
                        <i>richard@nimbus.com.ng</i>
                    </div>
                    <div className="">
                        <i>{<IoLocationSharp />}</i>
                        <i>Nimbus Media Limited, 16, Igbasan Street, Opebi, Ikeja, Lagos.</i>
                    </div>
                    <div className="">
                        <i>{<MdPhone />}</i>
                        <i>(+234)-816-225-6038</i>
                    </div>
                </div>
                <div className="copyright">
                    <div>
                        <img src={"https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png"} className="object-contain" alt="SSL Certificate" />
                    </div>
                    <p>COPYRIGHT NIMBUS WEBDEV 2021 - TERMS & CONDITIONS  PRIVACY POLICY</p>
                </div>
            </div>
        </div>
        
    );
}

export default Footer;