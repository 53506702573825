import React, { useEffect } from 'react'
import backgroundImg from "../../assets/images/Slides/slide6.png";
import backgroundImg1 from "../../assets/images/Slides/slide1.png";
import backgroundImg2 from "../../assets/images/Slides/slide2.png";
import backgroundImg3 from "../../assets/images/Slides/slide3.png";
import backgroundImg5 from "../../assets/images/Slides/slide5.png";
import backgroundImg7 from "../../assets/images/Slides/slide7.png";

function OurServices() {

    const cardData  = [
        {
            headerText : "Deploying Impactful Messaging",
            content : [
                "Because of the strategic placement, size and location ofout of home media, messages delivered through outdooradvertising proves very impactful as they are capable of easily getting the attention of consumers"
            ]
        },
        {
            headerText : "Optimizing Location-based Targeting",
            content : [
                "Because out of home advertising is extremely location-driven, over the years, we have mastered the art of making the best use of high traffic locations and outdoor advertising media.",
                "With the help of DOOH and other advertising tech stacks, we have developed capabilities to do much more with location than many other out of home advertising companies in Nigeria.",
                "Similar to online marketing services being able to provide detailed insights and analytics into ad engagement and conversions, we provide digital out of home advertising that can measure not only demographics of audience segments at a particular location, but also ad impressions, peak periods among other insights that are useful for management decision-making. "
            ]
        },
        {
            headerText : "Leveraging Outdoor Media Network",
            content : [
                "In order to add more value to our clients, we continue to expand our network of out of home media platforms and locations across Nigeria through partnerships and resource-sharing.",
                "Currently, our outdoor advertising media network in Nigeria includes Ikeja City Mall, Novare Mall Lekki, Festival Mall Festac, Jabi Lake Mall Abuja, Novare gateway Abuja, Heritage mall Ibadan, Cocoa Mall Ibadan, Ado-Bayero Mall, Kano, and all Justrite stores in Nigeria.",
                "We are on our way to building the largest digital out-of-home advertising network across Africa to enable us to reach more target audiences with personalized messaging at the right locations and timing to achieve maximum brand visibility"
            ]
        },
    ]

    const servicesData = [
        {
            heading: "Billboard Advertising",
            content: "We use large scale print and digital advertising boards (or hoardings) to promote a brand, offering, or a campaign at strategic high",
            img: backgroundImg1
        },
        {
            heading: "Lamp Post Advertising",
            content: "This is one of the most targeted outdoor advertising methods we use to ensure guaranteed exposure through small and compact billboards mounted on electric poles on road dividers",
            img: backgroundImg2
        },
        {
            heading: "Shopping Mall & Superstores",
            content: "We deploy creative ads in different sizes and formats in various parts of shopping malls and superstores that can give our client optimum brand visibility",
            img: backgroundImg3
        },
        {
            heading: "Transit Advertising",
            content: "We leverage outdoor media platforms inside and outside public vehicles like buses, trains, subways, cabs and also in the transit terminals or bus stations to provide innovative",
            img: backgroundImg5
        },
        {
            heading: "Guerilla Advertising",
            content: "Depending on budget, we deploy novel and unconventional methods to boost sales or attract consumers’ interest in a brand or business. Our Guerilla initiatives are often low-cost as they inspire",
            img: backgroundImg
        },
        {
            heading: "Brand Activation",
            content: "We create events, campaigns, flash mob, street and road shows or any kind of activity through which a brand drives customer actions. This OOH advertising",
            img: backgroundImg7
        }
    ]

    const Card = ({headerText, content}) =>{
        return(
            <div className="rounded-3xl w-full h-full overflow-hidden shadow-xl">
                <div className=" bg-yellow-300 text-center h-24 flex justify-center items-center px-2">
                    <h1 className="text-brand-color1 text-xl lg:text-2xl font-bold m-auto">{headerText}</h1>
                </div>
                <div className='bg-white text-center py-8 px-4 h-card-height'>
                {
                    content.map((data, index) =>{
                        return(
                            <p key={index}>{data}</p>
                        )
                    })
                } 
                </div>
            </div>
        )
    }

    const Services = ({ heading, content, image}) =>{
        return (
            <div className='text-center'>
                <img src={image} alt="service-img" className='rounded-full object-cover w-52 h-52 xl:w-72 xl:h-72'/>
                <h1 className='py-3 text-xl font-bold'>{heading}</h1>
                <p className='m-0 pb-3'>{content}</p>
                <button className="bg-charity-color2 text-white rounded-3xl px-4 py-2">Read More</button>
            </div>
        )
    }

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
        <div className="h-screen-height w-full relative">
            <img src={backgroundImg} alt="service-bg-img" className='w-full h-full object-cover'/>
            <div className="bg-charity-color2 w-85 md:w-9/12 xl:w-2/4 h-52 md:h-80 text-white flex justify-center items-center absolute left-0 bottom-12 rounded-br-full rounded-tr-full shadow-xl">
                <div className=" w-full md:w-500 lg:w-600 px-3 md:px-0">
                    <h1 className='text-2xl md:text-5xl lg:text-7xl font-bold'>NIMBUS MEDIA</h1>
                    <p className="text-yellow-300 text-sm sm:text-lg md:text-2xl font-medium">Out of Home Advertising Company in Nigeria</p>
                    <p className=' text-xs sm:text-xs md:text-lg'>We Are the Leading Out of Home Advertising Company in Nigeria with Out-of-home Media Network in High Traffic Locations across the Country</p>
                </div>
            </div>
        </div>
        <div className='w-full'>
            <div className="bg-charity-color2 text-white lg:rounded-tl-full w-full">
                <div className='w-full sm:w-600 lg:w-750 mx-auto text-center py-5 px-4 sm:px-0'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold'>WHY OUT OF HOME ADVERTISING?</h1>
                    <div className='text-lg'>
                        <p>Out of home advertising (OOH) is a form of advertising that is strategically placed at high traffic locations where consumers can be reached and engaged when they are generally outdoors or visiting specific public places for relaxation. </p>
                        <p>Traditionally outdoor advertising includes brand communications deployed on out-of-home media such as billboards, lamp posts, shopping mall display screens, bus shelters, benches, bus stations and road shows. </p>
                        <p>At Nimbus Media, we help our clients reach and engage their consumers by: </p>
                    </div>
                </div>
            </div>
        </div>
        <div className=" bg-gray-100 py-10">
            <div className='w-90 mx-auto flex flex-wrap'>
                {
                    cardData.map((data, index) => {
                        return(
                            <div className="w-full md:w-2/4 xl:w-1/3 min-h-300 px-2 py-3" key={index}>
                                <Card headerText={data.headerText} content={data.content}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className='w-full'>
            <h1 className='text-2xl sm:text-3xl md:text-4xl text-charity-color2 font-bold text-center pt-10'>OUR OOH ADVERTISING SERVICES?</h1>
            <div className='w-90 mx-auto flex flex-wrap py-10'>
                {
                    servicesData.map((data, index) => {
                        return(
                            <div key={index} className="w-full sm:w-2/4 lg:w-1/3 py-10 px-5">
                                <Services heading={data.heading} content={data.content} image={data.img}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </>
    )
}

export default OurServices