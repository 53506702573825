import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillMessage } from 'react-icons/ai';
import { Link as LinkScroll } from 'react-scroll'
import { useLocation } from "react-router-dom";

const GetStartedBtn = () => {
  const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
  return (
    (splitLocation[1] === "" || splitLocation[1] === "home") 
    ?
    <LinkScroll to="contact" spy={true} smooth={true} offset={50} duration={500} className='h-16 w-16 rounded-full bg-charity-color1 text-color29 flex justify-center items-center fixed bottom-3 right-3 z-60 text-4xl shadow-xl transform hover:scale-110 '>
        <AiFillMessage />
    </LinkScroll>
    :
    <Link to="/contact-us" className='h-16 w-16 rounded-full bg-charity-color1 text-color29 flex justify-center items-center fixed bottom-3 right-3 z-60 text-4xl shadow-xl transform hover:scale-110 '>
        <AiFillMessage />
    </Link>
  )
}

export default GetStartedBtn