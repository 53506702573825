import React from 'react'
import "./Charity.css";
import { Link } from "react-router-dom";
import CharityImage from "../../assets/images/nimbusAidProject.jpg";
import HeaderBackGroundImage1  from "../../assets/images/Group 15.png";
import HeaderBackGroundImage3  from "../../assets/images/Group 16.png";
import HeaderBackGroundImage2  from "../../assets/images/Rectangle 241.png";
import HeaderBackGroundImage4  from "../../assets/images/Ellipse 32.png";
import HeaderBackGroundImage5  from "../../assets/images/Ellipse 36.png";
import HeaderBackGroundImage6  from "../../assets/images/Ellipse 37.png";
import HeaderBackGroundImage7  from "../../assets/images/Group 5.png";
import ReactPlayer from 'react-player'
import { useInView } from 'react-intersection-observer';
import CharityCountDown from './CharityCountDown';
function Charity({ countDownDetail, btnState,timerValue }){

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
      });

    return (
        <>
            <div className="charity-container">
                <img className="background-image2 object-contain" src={HeaderBackGroundImage3} alt=""/>
                <div className="charity-header-container">
                    <div className='m-0 md:mr-24'>
                        <div className="header-background"><h1>Nimbus Aid Project</h1></div>
                    </div>
                    <img className="background-image1 object-contain" src={HeaderBackGroundImage1} alt=""/>
                </div>
                <div className={`${inView ? "charity-body-container charity-body-container-zoom" : "charity-body-container"}`} ref={ref}>
                    <div className="charity-image-container">
                    <img className="background-image3" src={HeaderBackGroundImage1} alt=""/>
                    <img className="background-image5" src={HeaderBackGroundImage5} alt=""/>
                    <img className="background-image6" src={HeaderBackGroundImage6} alt=""/>
                    <img className="background-image7" src={HeaderBackGroundImage7} alt=""/>
                    <img className="background-image4" src={HeaderBackGroundImage4} alt=""/>
                    <img className="charity-image" src={CharityImage} alt="charity_image" />
                    
                    {/* <div className="charity-image">
                        <ReactPlayer width="100%" height="100%" controls url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                    </div> */}
                    <img className="charity-image-background" src={HeaderBackGroundImage2} alt=""/>  
                    </div>
                    <div className="charity-content-container">
                        <p className=''> Nimbus Media hosts an annual effort now named "Nimbus Aid Project" as part of their commitment to giving back to the community.</p>
                        <p className=''>This program is valued at over 10 million Naira, and it involves Nigerians nominating certain noble organizations, categories and  certain sub-sectors in the community  with the winning organization receiving a month of free visibility on all of our digital screens.</p>
                    </div>
                </div>
                {
                    countDownDetail.round !== null && 
                    <div className="charity-btn-wrapper">
                        <Link to="/charity">
                            <button className="charity-btn font-bold text-brand-color1 hover:bg-brand-color1 hover:text-charity-color1" disabled={btnState}>Nominate Now</button>
                        </Link>
                    </div>
                }
                
            </div>
            {
                countDownDetail.round !== null && 
                <CharityCountDown timerValue={timerValue} />
            }
        </>
    )
}

export default Charity;
