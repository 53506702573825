import React,{useState,useRef,useEffect,useContext} from 'react'
import "./AdminGallery.css";
import { TiUpload } from "react-icons/ti";
import GalleryCard from '../Gallery/GalleryCard';
import PaginationComponent from '../Pagination';
import DeleteModal from './DeleteModal';
import { Link as LINK } from 'react-router-dom';
import { GalleryContext } from '../../Utils/Context/galleryContext';
import image_Not_Found from "../../assets/images/imageNotFound.png";
import axios from 'axios';
import SkeletonGallery from '../Skeletons/SkeletonGallery';
import Loader from '../Loader/Loader';

function AdminGallery(props) {
    const filterBy = props.section;
    
    const { gallery, getGallery, isLoading } = useContext(GalleryContext);
    const [ loading, setLoading ] = useState(false);
    const [ currentGallery, setCurrentGallery ] = useState([null]);
    const [ id, setId ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ selectedImage, setSelectedImage ] = useState([]);
    const [ uploadedImages, setUploadedImages ] = useState([]);
    const [ formErrors, setFormErrors ] = useState({});
    const [ deleteText, setDeleteText ] = useState({
        msg: "",
        status: ""
    });

    
    const [ backDropIsActive , setBackDropIsActive ] = useState(false);
    const [ currentPage, setCurrentPage] = useState(1);
    const [ postPerPage] = useState(9);
    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost  = indexOfLastPost-postPerPage;
    var currentPosts = (currentGallery.length !== 0 ? currentGallery.slice(indexOfFirstPost, indexOfLastPost) : []);
    const  totalPosts = currentGallery.length;
    const [ imageIsDeleted, setImageIsDeleted ] = useState(false);
    const [ sectionIsSet, setSectionIsSet ] = useState(false);
    const galleryApi = process.env.REACT_APP_GALLERY_API;
    const [ imageNotFound, setImageNotFound ] = useState("");

    const [ message, setMessage ] = useState({
        msg: "",
        status: 0
    });
   
    const handleSetPage = (number) =>{
        setCurrentPage(number);
        window.scrollTo(0, 0);
    }
    const chooseFile = useRef(null);

    const handleInputOnChange = (e) =>{
        setCategory(e.target.value);
    }
    
    
    const handleOnSave = (e) =>{
        e.preventDefault();
        setFormErrors(validate(category));
        if(validate(category).status){
            var formData = new FormData();
            formData.append("category", category);
            for(let i=0; i< uploadedImages.length; i++){
                formData.append("photo",uploadedImages[i]);
            }
            addImage(formData);
           
        }
        
    }

    const handleFileOnchange = (event) =>{
        let tempArr = [];
        if(event.target.files && event.target.files.length <= 10){
            setUploadedImages(event.target.files)
            const fileArray = Array.from(event.target.files).map((file) => URL.createObjectURL(file))
                setSelectedImage((prevState) => prevState.concat(fileArray))
                Array.from(event.target.files).map((file) => URL.revokeObjectURL(file))
        }
        else{
            for(let i=0; i < 10; i++){
                tempArr.push(event.target.files[i]);
            }
            const fileArray = Array.from(tempArr).map((file) => URL.createObjectURL(file));
            setSelectedImage((prevState) => prevState.concat(fileArray));
            Array.from(tempArr).map((file) => URL.revokeObjectURL(file));
            setDeleteText({
                msg:"Can't Select more than 10 images",
                status: "0"
            });
            setImageIsDeleted(true);
        }
        
        // setUploadedImages([]);
        // _.forEach(event.target.files, file =>{
        //     formData.append('photo', file);
        // })
        // // formData.append("category",)
        // if(event.target.files && event.target.files.length <= 10){
        //     const fileArray = Array.from(event.target.files).map((file) => URL.createObjectURL(file))
        //     console.log(fileArray);
        //     setSelectedImage((prevState) => prevState.concat(fileArray))
        //     Array.from(event.target.files).map((file) => URL.revokeObjectURL(file))
        // }
        // else{
        //     alert("Cant select more than 10 images");
        // }
    }
    const renderPhotos = (source) =>{
        return source.map((photo ,id) =>{
            return (
                <div className="col-span-1">
                    <img src={photo} key={id} alt={photo} className="h-40 w-44 object-cover"/>
                </div>
            )
        })
    }

    const handleOnUpload = () =>{
        chooseFile.current.click();
    }
   
    const handleOnClose = () =>{
            setSelectedImage([]);
    }
    const handleImageOnDelete = (id) =>{
        setId(id);
        setBackDropIsActive(true);
    } 
    const closeBackDrop = () =>{
        setBackDropIsActive(false);
        setImageIsDeleted(false);
    }
    const handleImgDelete = () =>{
        deleteImage(id);
        setBackDropIsActive(false);
    }
    const handleImgOnClick=(id)=>{
        setId(id);
    }
    const handleSectionOnClick = () =>{
       setSectionIsSet(!sectionIsSet);
    }
    const filterGallery = (galleryData) =>{
        if(filterBy === undefined){
            return galleryData;
        }
        if(galleryData.category.toLowerCase() === filterBy){
            return galleryData; 
        }  
    }

    const validate = (input) => {
        let errors = {
            status : true
        }

        if(!input){
            errors.status = false;
            errors.message = "Section is required !!!";
        }
        return errors;
    }

    const addImage = (payload) =>{
        setLoading(true);
        axios.post("https://nimbus-media-staging.herokuapp.com/api/v1/gallery", payload,{withCredentials: true})
        .then((res) =>{
            setLoading(false);
            setDeleteText({
                msg: "Image Added Successfully !!!", 
                status: ""
            });
            setLoading(false);
            getGallery();
            setImageIsDeleted(true);
            setUploadedImages([]);
            setSelectedImage([]);
        })
        .catch(err =>{
            setDeleteText({
                msg: err.response.data.message,
                status: "0"
            });
            setLoading(false);
            setImageIsDeleted(true);
        })
    }

    const deleteImage = (id) =>{
        setLoading(true)
        axios.delete(galleryApi +"/" + id, {withCredentials: true})
        .then((response) =>{
            setDeleteText({
                msg: "Image Deleted Successfully !!!", 
                status: ""
            });
            setLoading(false);
            getGallery();
            setImageIsDeleted(true);
        })
        .catch((err) =>{
            setDeleteText({
                msg: err.response.data.message,
                status: "0"
            });
            setLoading(false);
            setImageIsDeleted(true);
        })
    }

    // const updateMessage = (msg, status) =>{
    //     setMessage({
    //         msg: msg,
    //         status: status
    //     });
    //     setTimeout(() => {
    //         setMessage({
    //             msg: "",
    //             status: 0
    //         });
    //     }, 7000);
    // }
   
    useEffect(() => {
        window.scrollTo(0, 0);
        if(gallery.filter(filterGallery).length === 0){
            setImageNotFound(image_Not_Found);
        }
        setCurrentGallery(!filterBy ? gallery : gallery.filter(filterGallery));  
        setCurrentPage(1);
    }, [filterBy, gallery])
   
    return (
        <div className="main-gallery-container">
        {
            loading && <Loader color="#FFFFFF" />
        }
            {backDropIsActive &&
                <div className="back-drop" onClick={closeBackDrop}>
                </div>
            }
            {backDropIsActive &&
            <div className="delete-img-alert">
                <div className="delete-img-alert-wrapper">
                    <p>Are you sure you want to delete this image</p>
                    <div className="delete-img-alert-btn">
                        <button className="yes-btn" onClick={handleImgDelete}>Yes</button>
                        <button className="no-btn" onClick={closeBackDrop}>No</button>
                    </div>
                </div>
            </div>
            }
            {
                imageIsDeleted && <DeleteModal onClick={closeBackDrop} text={deleteText} />
            }
            <div className="main-gallery-header">
                <h1>Our Gallery</h1>
            </div>
            <div className="w-4/5 m-auto">
            <p className={`w-full lg:w-2/4 ${message.status === 1 ? "bg-red-300 text-red-600 py-2 mb-2 mt-10": ""} ${message.status === 2 ? "bg-green-200 text-green-600 py-2 mb-2 mt-10": ""} pl-6`}>{message.msg}</p>
            {
                selectedImage.length !== 0 &&
                    <form onSubmit={handleOnSave}>
                        <div className="text-brand-color1">
                            <div>
                                <label htmlFor="img-section" className="font-bold text-xl">Section</label>
                            </div>
                            <select className="md:w-80 w-60 rounded-full border-brand-color1 border-2 px-2 py-2" name="category" onChange={handleInputOnChange}>
                                <option value="" selected disabled>select Category</option>
                                <option>Activation</option>
                                <option>Branding</option>
                                <option>Digital</option>
                            </select>
                        </div>
                        <p className="text-red-500 py-2 m-0">{formErrors.message}</p>
                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 gap-8 my-10">
                            {renderPhotos(selectedImage)}
                        </div>
                        <div>
                            <div className="">
                                <button className="rounded-full bg-brand-color1 text-color28 w-20 md:w-32 h-10 md:h-12 mr-3 border-2 hover:border-brand-color1 hover:text-brand-color1 hover:bg-white" type="submit">Save</button>
                                <button className="border-brand-color1 border-2 rounded-full w-20 md:w-32 h-10 md:h-12 hover:bg-brand-color1 hover:text-white" onClick={handleOnClose}>Close</button>
                            </div>
                        </div>
                    </form>
            }
            </div>
            {
                selectedImage.length === 0 &&
                <div className="gallery-section-nav">
                    <div className="gallery-section-inner-nav">
                        <div className="gallery-nav-wraper">
                            <div>
                                <LINK className={props.section === undefined ? "activeSection" : "inactive"} to="/gallery-update" onClick={handleSectionOnClick}>All</LINK>
                            </div>
                            <div>
                                <LINK className={props.section === "activation" ? "activeSection" : "inactive"} to="/gallery-update/activation" onClick={handleSectionOnClick}>Activation</LINK>
                            </div>
                            <div>
                                <LINK className={props.section === "branding" ? "activeSection" : "inactive"} to="/gallery-update/branding" onClick={handleSectionOnClick}>Branding</LINK>
                            </div>
                            <div>
                                <LINK className={props.section === "digital" ? "activeSection" : "inactive"} to="/gallery-update/digital" onClick={handleSectionOnClick}>Digital</LINK>
                            </div>
                        </div>
                    </div>
                </div>
            }
                <div className="upload-gallery-wrapper">
            {
                selectedImage.length === 0 &&
                <div className="upload-box">
                    <div className="inner-upload-box">
                        <p>(Drag and Drop Image file here)</p>
                        <input id="file" multiple type="file" accept="image/*" maxLength="5" onChange={handleFileOnchange} ref={chooseFile}/>
                        <div onClick={handleOnUpload}>
                            <i>{<TiUpload />}</i>
                            <h1>Upload</h1>
                        </div>
                    </div>
                </div>
            }
                <div className="upload-gallery-text">
                    
                </div>

                </div>
            
            <div className="gallery1-slider-container">
                <div className="w-4/5 m-auto">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {isLoading &&
                            [1,2,3,4,5,6].map((n,index) =>{ 
                                return( 
                                    <SkeletonGallery key={n} theme="light"/>
                            )})
                        } 
                        {
                        currentPosts.length !== 0 &&
                            currentPosts[0] !== null &&
                            currentPosts.map((images ,id) =>{
                            return(
                                 <GalleryCard id={images._id} galleryImg={images.imageUrl} title={images.category} onDelete={handleImageOnDelete} key={id} deleteBtnActive={true} handleImgOnClick={handleImgOnClick}/>    
                            )
                        })
                        }
                    </div>
                    {
                        currentGallery.length === 0 &&
                            <div className='text-center'>
                                <img src={imageNotFound} alt="no_image" className="w-52 h-52 object-contain" />
                            </div>
                    }
                </div>
            </div>
            <div className="main-gallery-nav">
            <PaginationComponent numOfPosts={totalPosts}  handleSetPage={handleSetPage}/>
            </div>
        </div>
    )
}

export default AdminGallery;
