import { React, createContext, useState, useEffect} from "react";
import axios from "axios";

export const CharityContext = createContext({});
const CharityContextProvider = (props) =>{
    const round = localStorage.getItem("round");

    const teamProfileApi = process.env.REACT_APP_TEAM_PROFILE_API;
//    const teamPositionsApi = process.env.REACT_APP_GET_TEAM_POSITION_API;
    const [loading, setLoading ] = useState(false);
     const [ topNominees, setTopNominees ] = useState([]);
    const [ countDownDetails, setCountDownDetails] = useState({
        round: null,
        start: "",
        stop: ""
    });
    const getTopNominatons = () =>{
        setLoading(true);
        axios.get("https://nimbus-staging.onrender.com/api/v1/three")
        .then((res) =>{
            setLoading(false);
            console.log(res)
            if(res.data.data.entry.length !== 0){
                const [arr] = res.data.data.entry;
                const {first,second, third} = arr;
                setTopNominees([
                    first,
                    second,
                    third
                ])
                console.log(res)
            }
        })
        .catch(err =>{
            setLoading(false);
            console.log(err);
        })
    }


    // const [ countDownDetailsId, setCountDownDetailsId ] = useState("");
    // const [ teamProfile, setTeamProfile ] = useState([]);
    // const [ rankMessage, setRankMessage ] = useState({
    //     message: "",
    //     status: ""
    // });
    // const [ ranks, setRanks ] = useState([]);
    // const [ isLoading, setIsLoading ] = useState(true);

    // const getTeamProfile = () =>{
    //     setIsLoading(true)
    //     axios.get(teamProfileApi)
    //     .then((response) =>{
    //         if(response.data.status === "success"){
    //         setTeamProfile(response.data.data.images);
    //         setIsLoading(false);
    //         }
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }
    // const createProfile = (data) =>{
    //     axios.post(teamProfileApi,data,{withCredentials: true})
    //     .then((response) =>{
    //         getTeamProfile();
    //     })
    //     .catch((err) =>{
    //         console.log(err.status);
    //     })
    // }
    // const editProfile = (id,data) =>{
    //     axios.patch(teamProfileApi + "/" + id,data,{withCredentials: true})
    //     .then((response) =>{
    //         getTeamProfile();
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }
    // const deleteProfile = (id) =>{
    //     axios.delete(teamProfileApi +"/" + id, {withCredentials: true})
    //     .then((response) =>{
    //         getTeamProfile();
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }

    // const getTeamPositions = () =>{
    //     setIsLoading(true);
    //     axios.get(teamPositionsApi, {withCredentials: true})
    //     .then((response) =>{
    //         if(response.data.status === "success"){
    //         setIsLoading(false);
    //         setRanks(response.data.data.ranks);
    //         }
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }

    // const createTeamPositions = (payload) =>{
    //     axios.post(teamPositionsApi, payload, {withCredentials: true})
    //     .then((response) =>{
    //             updateRankMessage("Rank Successfully Created!!", "1");
    //             getTeamPositions();
    //     })
    //     .catch((err) =>{
    //         updateRankMessage("Duplicate Fields!!!", "0");
    //     })
    //         updateRankMessage("", "");
    // }
    // const deleteTeamPositions = (id) =>{
    //     axios.delete(teamPositionsApi +"/" + id, {withCredentials: true})
    //     .then((response) =>{
    //         getTeamPositions();
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }
    // const createCountDownConfiguration = (paylod) =>{
    //     axios.post("https://nimbus-media-staging.herokuapp.com/api/v1/date", paylod,{withCredentials: true})
    //     .then((res) =>{
    //         console.log(res.data.status)
    //         if(res.data.status === "success"){
    //             if(res.data.data.entry.length === 0){
    //                 setCountDownDetails((prevState) =>{
    //                     return{
    //                         round: null,
    //                         start: "",
    //                         stop: ""
    //                     }
    //                 })
    //             }
    //             else{
    //                 setCountDownDetailsId(res.data.data.entry._id);
    //                 setCountDownDetails((prevState) =>{
    //                     return{
    //                         ...prevState,
    //                         round: res.data.data.entry.round,
    //                         start: res.data.data.entry.start,
    //                         stop: res.data.data.entry.stop
    //                     }
    //                 })
    //             }
    //         }
    //     })
    //     .catch((err) =>{
    //         console.log(err);
    //     })
    // }
    // const getCountDownConfiguration = () =>{
    //     axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/date")
    //     .then((res) =>{
    //         if(res.data.status === "success"){
    //             if(res.data.data.entry.length === 0){
    //                 setCountDownDetails((prevState) =>{
    //                     return{
    //                         round: null,
    //                         start: "",
    //                         stop: ""
    //                     }
    //                 })
    //             }
    //             else{
    //                 const details = {
    //                         round: res.data.data.entry[0].round,
    //                         start: res.data.data.entry[0].start,
    //                         stop: res.data.data.entry[0].stop
    //                 }
    //                 setCountDownDetails((prevState) =>{
    //                     return{
    //                         ...details
    //                     }
    //                 })
    //             }
    //         }
            
    //     })
    // }
   

    // const deleteCountDownConfiguration = (id) =>{
    //     axios.delete(`https://nimbus-media-staging.herokuapp.com/api/v1/date/${id}`)
    //     .then((res) =>{
    //         console.log(res)
    //         // if(res.data.status === "success"){
    //         //     if(res.data.data.entry.length === 0){
    //         //         setCountDownDetails((prevState) =>{
    //         //             return{
    //         //                 round: null,
    //         //                 start: "",
    //         //                 stop: ""
    //         //             }
    //         //         })
    //         //     }
                
    //         // }
           
    //     })
    // }

    // const updateRankMessage = (message, status) => {
    //     setRankMessage({
    //         message: message,
    //         status: status
    //     });
    //     setTimeout(() => {
    //         setRankMessage({
    //             message: "",
    //             status: ""
    //         });
    //     }, 5000);
    // }

    
    const getCountDownConfiguration = () =>{
        axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/date")
        .then((res) =>{
            if(res.data.status === "success"){
                if(res.data.data.entry.length !== 0){
                    localStorage.setItem("countDownId",res.data.data.entry[0]._id );
                    setCountDownDetails({
                        round: res.data.data.entry[0].round,
                        start: res.data.data.entry[0].start,
                        stop: res.data.data.entry[0].stop
                    })
                }
            }
        })
    }
    
    useEffect(() => {
        getCountDownConfiguration();
        getTopNominatons();
    }, [])

    return(
        <CharityContext.Provider value={{ topNominees, countDownDetails, loading }}>
            {props.children}
        </CharityContext.Provider>
    )
}
export default CharityContextProvider;

