import React, {useState, useContext} from 'react'
import TeamImage from './TeamImage';
import Footer from '../Footer/Footer';
import "./Team.css";
import { TeamProfileContext} from "../../Utils/Context/teamProfileContext";
import TeamBio from './TeamBio';
import imageNotFound from "../../assets/images/imageNotFound.png";
import TeamHeader from './TeamHeader';
import SkeletonElement from '../Skeletons/SkeletonElement';
import SkeletonProfile from '../Skeletons/SkeletonProfile';

function Team(props) {
    
    const { teamProfile, isLoading, deleteProfile } = useContext(TeamProfileContext);
    const [ imageId, setImageId ] = useState("");
    const [ backDropIsActive , setBackDropIsActive ] = useState(false);
    const handleProfileOnClick = (id) =>{
        setImageId(id);
        setBackDropIsActive(true);
    }
    const handleProfileOnClose = () =>{
        setBackDropIsActive(false);
    }
    const Team_Bio = backDropIsActive && 
        <>
        <div className="back-drop" onClick={handleProfileOnClose}></div>
        {
            teamProfile.map((data) =>{
                return(
                imageId === data._id && <TeamBio  image={data.imageUrl} position={data.position} name={data.name} content={data.bio} onClick={handleProfileOnClose} />
                )
            })
        }
        </>
    const Team_Img = <div className="team-profile-frame">
        {
            (teamProfile.length === 0 ) &&
                    [1,2,3,4,5,6].map((n) =>{ return( 
                        <div className="team-img-frame">
                            <SkeletonProfile key={n} theme="light"/>
                        </div>
                    )}
            ) 
        }      
        {
            teamProfile.length !== 0 &&
            teamProfile.map((data) =>{
                return(
                    <div key={data._id} className="team-img-frame">
                        <TeamImage image={data.imageUrl} position={data.position} name={data.name} onClick={handleProfileOnClick.bind(null, data._id)}  profileEdit={false}/>
                    </div>
                    
                    
                )
            })
        }  
        {/* {
            (!isLoading && teamProfile.length === 0)  &&
            <img className="image-not-found" src={imageNotFound} alt="image_notfound" />
        }    */}
        </div>
    return (
        <>
            <div className="team-container relative">
                {Team_Bio}
                <TeamHeader />
                {Team_Img}
                
            </div>
            <Footer footerBodyVisible={false} footerHeadVisible={true} blogFooterVisible={true}/>
        </>
    )
}

export default Team;
