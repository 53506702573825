import React from  "react";
import "./LandingPageHeader.css";
import { IoIosArrowDown } from "react-icons/io";
import { Link as LINK } from "react-router-dom";
import { Link } from "react-scroll";
import Typewriter from 'typewriter-effect';
import Slider from "./Slider";

const LandingPageHeader = () =>{
    return(
        <div className="LandingPageHeader relative">
            <div className="LandingPageHeader-inner-container absolute top-0 z-50">
                <div className="LandingPageHeader-container">
                    <div className="header-content">
                        <div>
                            <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold leading-snug">
                                <Typewriter
                                    options={{
                                        strings: [
                                            "Be Where Your Customers Are", 
                                            "Reach Over 1.5 Million People Across All Platforms Monthly",
                                            "We Are the Leading Digital Out-of-home Advertising Agency in Nigeria",
                                            "Engage Your Audiences with Digital Outdoor Advertising Where They are Most Relaxed"
                                            ],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </h1>
                            <p className="font-medium">With our outstanding digital out-of-home advertising opportunities, you are directly in the face of your target audience.</p> 
                        </div>
                        <div className="header-btn-wrapper">
                            <LINK to="/about/opportunities">
                                <button className="header-btn">Explore</button>
                            </LINK>
                        </div>
                        <div className="nav-icon-container animate-bounce cursor-pointer">
                        <Link to="mission-vission" spy={true} smooth={true} offset={50} duration={500} >
                            <i>{<IoIosArrowDown />}</i>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Slider />
        </div>
    )
}
export default LandingPageHeader;