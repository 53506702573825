import React from 'react';
import "./BlogFooter.css";

function BlogFooter(props) {
    return (
        <div className="blog-footer" style={{display: props.blogFooterVisible ? "block" : "none"}}>
            <div className="blog-inner-footer">
                {props.posts.map((post) =>{
                    return(
                        <div className="footer-card-wrapper">
                        <div className="footer-card">
                            <div>
                                <img src={post.thumbnail} alt="footer-icon"/>
                                <h1>{post.title}</h1>
                                <p>{post.author}</p>
                                </div>
                                <hr/>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}



export default BlogFooter;
