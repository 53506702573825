import React, {useState, useEffect} from 'react'
import SectionHeader from './SectionHeader';
import axios from 'axios';

function SortedEntry({ sortedEntries, handleClearAll }) {
    const round = localStorage.getItem("round");
    const [ countDownMessage, setCountDownMessage ] = useState({
        message: "",
        status: ""
    });

    const updateCountDownMessage = (message, status) => {
        setCountDownMessage({
            message: message,
            status: status
        });
        setTimeout(() => {
            setCountDownMessage({
                message: "",
                status: ""
            });
        }, 5000);
    }

    return (
        <div className="w-full pt-4 pb-5">
            <SectionHeader headerText="Sorted Entries" />
            <p className={`${countDownMessage.status === "0" ? "bg-red-300 text-red-600 py-2 my-2": ""} ${countDownMessage.status === "1" ? "bg-green-200 text-green-600 py-2 my-2": ""} pl-6`}>{countDownMessage.message}</p>
            {
                sortedEntries.length !== 0 ?
                <div className="w-full text-brand-color1">
                <table className="w-4/5 m-auto mt-4">
                    <tr className="bg-charity-color9 w-4/5">
                        <th className="opacity-80 font-bold py-1 pr-5">S/N</th>
                        <th className="opacity-80 font-bold py-1">Nominations</th>
                        <th className="opacity-80 font-bold py-1">Pool Count</th>
                    </tr>
                    {
                        sortedEntries.map((data, index) =>{
                            return(
                                <tr>
                                    <td className=" py-3 pl-3">{index + 1}</td>
                                    <td className="py-3">{data._id}</td>
                                    <td className="py-3">{data.num}</td>
                                </tr>
                            )
                        })
                    }
                   
                </table>
                <div className="py-10 w-4/5 m-auto">
                    <button className="w-32 py-2 rounded-full border-2 border-brand-color1 text-brand-color1 hover:bg-charity-color8 hover:text-white font-semibold text-lg" onClick={handleClearAll}>Clear all</button>
                </div>
                </div>
                :
                <div className="py-16">
                    <p className="text-center text-brand-color1 font-bold text-2xl">Empty</p>
                </div>
            }
            
        </div>
    )
}

export default SortedEntry
