import React from 'react'
import "./TeamBio.css";
import { MdClose } from "react-icons/md";

function TeamBio(props) {
    
    return (
        <div className="team-bio">
            <div className="team-bio-header">
                <i onClick={props.onClick} className='hover:text-color24'>{<MdClose />}</i>
            </div>
            <div className="team-bio-content-contianer">
                <div className="team-bio-img">
                    <img src={props.image} alt="teamImage" className='object-cover'/>
                </div>
                <div className="team-bio-content">
                    <div>
                        <h1 className="team-team">{props.name}</h1>
                        <p className="team-position">{props.position.includes("DIRECTORS") ? "DIRECTOR" : props.position}</p>
                        <p className="team-content-text pt-1">{props.content}</p>
                    </div>
                </div>
            </div>
            <div className="team-bio-footer">
            </div>
        </div>
    )
}

export default TeamBio
