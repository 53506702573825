import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import BackDrop from '../BackDrop';

function Verification(props) {
    
    const navigate = useHistory();

     const btnClick = () =>{
        navigate.push("/charity");
     }
     const handleBackDrop = () =>{
        
     }
    return (
        <>
            <BackDrop onclick={handleBackDrop} indexValue={"40"}/>
            {props.children}
            <div className="absolute transform -translate-y-2/4 -translate-x-2/4 top-2/4 left-2/4 w-11/12 sm:w-480 h-330 rounded-xl bg-white z-50 text-color26">
                {
                    !(props.errorMessage === "") &&
                    <div className="h-24 w-24 bg-color24 rounded-full mx-auto -mt-12">
                        {props.icon}
                    </div>
                }
                
            <div className="absolute top-40 left-2/4 transform -translate-y-2/4 -translate-x-2/4 text-center w-85 font-bold m-auto">
            {
                props.errorMessage && 
                <p className="text-red-600 bg-red-200 m-0 p-2">{props.errorMessage}</p>
            }
           
                <p className="text-2xl py-3 m-0">{props.messageHeader}</p>
                <p className="text-sm m-0">{props.messageText1}</p>
            </div>
            <div className="flex absolute justify-center items-center bottom-0 w-full bg-color27 py-2 rounded-b-xl">
                
                {
                    props.btnActive &&
                    <div className="w-2/4 py-2 pl-2 pr-4">
                        <button className="text-color24 bg-color28 w-full py-3 rounded-md hover:bg-color24 hover:text-color27" onClick={btnClick}>{props.btnText}</button>
                    </div> 
                }
                {
                    props.btnText1 &&
                    <div className="w-2/4 py-2 pl-4 pr-2">
                        <button className=" border-2 bg-color24 text-color27 w-full py-3 rounded-md hover:bg-color28 hover:text-color24" onClick={props.handleClick}>{props.btnText1}</button>
                    </div>
                }
            </div>
            </div>
        </>
        
    )
}

export default Verification;
