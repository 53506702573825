import React,{ useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { CharityContext } from '../Context/CharityContext';

function CharityProtectedRoute({ component: Component, ...rest}) {
    const round = localStorage.getItem("round");
    const { countDownDetails } = useContext(CharityContext);

    return (
       <Route 
        {...rest}
           render={(props) =>{
            if(countDownDetails.round !== null){
                return(
                    <Component {...props} />
                )
            }
            else{
                return(
                    <Redirect  to={{pathname:"/home", state: { from: props.location }}}/>
                )
            }
           }}
       />
    )
}

export default CharityProtectedRoute;
