import React,{useState, useContext} from 'react'
import AdminGallery from '../Components/GalleryUpdate/AdminGallery';
import Footer from '../Components/Footer/Footer';
import AdminHeader from '../Components/Header/AdminHeader';
import { GalleryContext } from '../Utils/Context/galleryContext';
import Loader from '../Components/Loader/Loader';
import axios from "axios";
function GalleryUpdate(props) {

    return (
        <>
            <AdminHeader />
            <AdminGallery section={props.match.params.id}>
                <Loader color="#FFFFFF"/> 
            </AdminGallery>
            <Footer footerBodyVisible={false} footerHeadVisible={true} posts={[]} blogFooterVisible={false}/>
        </>
    )
}
export default GalleryUpdate;
