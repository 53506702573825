import React from 'react'
import SigninFooter from '../Components/Footer/SigninFooter';
import PasswordReset from '../Components/Signin/PasswordReset';
function PasswordResetPage() {
    return (
        <div className="body-wrapper">
            <div>
                <PasswordReset />
                <SigninFooter />
            </div>
        </div>
    )
}

export default PasswordResetPage;
