import { React, createContext, useState, useEffect} from "react";
import axios from "axios";

export const GalleryContext = createContext({});

const GalleryContextProvider = (props) =>{
    const galleryApi = process.env.REACT_APP_GALLERY_API;
    const [ gallery, setGallery ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const getGallery = () =>{
        setIsLoading(true)
        axios.get(galleryApi)
        .then((response) =>{
            if(response.data.status === "success"){
            setGallery(response.data.data.images);
            setIsLoading(false);
            }
        })
        .catch((err) =>{
        })
    }
    
    
    useEffect(() => {
       getGallery();
    }, [])
    return(
        <GalleryContext.Provider value={{gallery, getGallery, isLoading, setIsLoading}}>
            {props.children}
        </GalleryContext.Provider>
    )
}
export default GalleryContextProvider;