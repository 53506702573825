import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function Loader({ color }) {
    return (
        <i className="mr-2">
            <Spinner
                variant={color}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        </i>
    )
}

export default Loader;
