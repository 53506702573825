import React from 'react';
import "./Footer.css";
import "./SigninFooter.css";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";
function SigninFooter() {
    return (
        <div>
           <div className="footer-contact">
                <div className="order1">
                    <i>{<FaRegCheckCircle />}</i>
                    <Link to="/">richard@nimbus.com.ng</Link>
                </div>
                <div className="order2">
                    <i>{<IoLocationSharp />}</i>
                    <Link to="/">Nimbus Media Limited, 16, Igbasan Street, Opebi, Ikeja, Lagos.</Link>
                </div>
                <div className="order3">
                    <i>{<MdPhone />}</i>
                    <Link to="/">+2348162256038</Link>
                </div>
                </div> 
        </div>
    )
}

export default SigninFooter
