import React from 'react'
import "./Signin.css";
import logo from "../../assets/images/NIMBUS_WHITE_LOGO.png";
import backgroundDots from "../../assets/images/smallRectangle.png";
import {Link} from "react-router-dom";
function Signup() {
    return (
        <div className="signin-container">
            <div className="signin-header">
                <div>
                    <img className="signin-header-logo" src={logo} alt="nimbus logo"/>
                    <img src={backgroundDots} alt=""  className="signin-yellow-dots"/>
                </div>
            </div>
            <div className="signin-body">
                <img src={backgroundDots} alt="" className="signin-yellow-dots"/>
                <div className="signin-inner-body" id="inner-body">
                    <div className="signin-backgroundImg">

                    </div>
                    <div className="signin-backgroundCircle">
                    
                    </div>
                    <div className="signin-form-container">
                        <form className="signin-form">
                            <div className="signin-input-wrapper">
                                <p>Create Your Account</p>
                                <div className="sigup-input-wrapper">
                                    <label>Staff ID</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>First Name</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>Last Name</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>Email Address</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>Phone Number</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>Password</label>
                                    <input type="text" className="siginup-input"/>
                                    <label>Retype Password</label>
                                    <input type="text" className="siginup-input"/>
                                    <Link  to="/edit-team"><button>Sign up</button></Link>
                                </div>
                            </div>
                            <p className="signup-bottom-text">By clicking the ‘’Create My Account’’ button, you agree to Nimbus media’s terms of acceptable use</p>
                        </form>
                    </div>
                    <div className="signup-bottom-text">
                        <p>Already have an account? <Link  to="/signin">Login</Link></p>
                    </div>
                </div>
            </div>
            <div className="signin-footer">
                <button className="signup-footer-btn">Contact us</button>
                <img src={backgroundDots} alt="" className="signin-yellow-dots" />
            </div>
            <div className="blank-div">
            </div>
        </div>
    )
}

export default Signup;
