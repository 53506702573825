import React from 'react'
import AboutView from '../Components/About/About';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import GetStartedBtn from '../Components/GetStartedBtn';
import { Helmet } from "react-helmet-async";
function About(props) {

    return (
        <div className="body-wrapper relative">
            <Helmet>
                <title>Outdoor Advertising Agency in Lagos, Nigeria | NIMBUS MEDIA</title>
                <meta name="description" content="Nimbus Media is the Leading Outdoor Advertising Agency in Lagos, Nigeria with a Network of OOH Nationwide and Proven Records with Brands across All Sectors."/>
            </Helmet>
           <Header />
            <div className="about">
                <AboutView param={props.match.params.id} />
            </div>
            <GetStartedBtn />
            <Footer footerBodyVisible={true} footerHeadVisible={true} posts={[]} blogFooterVisible={false}/>
        </div>
        )
}

export default About;
