import React from 'react'

function BackDrop(props) {
    const handleOnClick = (props) =>{
       onclick();
    }
    return (
        <div className={`fixed top-0 w-full h-screen bg-black opacity-50 z-${props.indexValue} blur-md`} onClick={props.handleOnClick}>
            {props.children}
        </div>
    )
}

export default BackDrop;
