import React from 'react'
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import ServicesFooter from '../Components/OurServices/Footer';
import OurServices from '../Components/OurServices/OurServices'

function Services() {
  return (
    <div className="body-wrapper">
      <Header />
      <OurServices />
      <ServicesFooter />
    </div>
  )
}

export default Services;