import React,{ useState, useEffect } from "react";
import "../Header/Mission_Vission.css";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import { SliderData } from "../Header/SliderData";
import { useHistory } from "react-router-dom";

function OurWorks() {
  const navigate = useHistory();

  const [numOfSlideToShow,setNumOfSlideToShow] = useState(3);
  
    
  function SampleNextArrow(props) {
    const { onClick } = props;

    return (
      <div className="forward-arrow-container" onClick={onClick}>
          <i className="image-nav" >{<IoIosArrowForward />}</i>
      </div>
    );
  }
      
  function SamplePrevArrow(props) {
    const { onClick } = props;
    
    return (
        <div className="backward-arrow-container" onClick={onClick}>
            <i className="image-nav" >{<IoIosArrowBack />}</i>
        </div>
    );
  }
  

  const handleResize = () => {
    if (window.innerWidth <= 530) {
      setNumOfSlideToShow(1);
    }
    else if (window.innerWidth <= 800) {
      setNumOfSlideToShow(1);
    }
    else if (window.innerWidth <= 990) {
      setNumOfSlideToShow(2);
    }
    else if (window.innerWidth <= 1024) {
      setNumOfSlideToShow(2);
    }
    else if (window.innerWidth <= 1240) {
      setNumOfSlideToShow(2);
    }
    else {
        setNumOfSlideToShow(3);
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: numOfSlideToShow,
    slidesToScroll: 1,
    centerPadding: "60px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  const handleImgOnClick = (path) =>{
    navigate.push(`/gallery/${path}`);
  }

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

    return (
        <div className="slider-wrapper" id="mission-vission">
           <div className="slider-header">
                <h1 className="font-bold text-3xl ">Our Works</h1>
                <div className="mx-auto w-3/4 sm:w-480">
                  <p className="text-about-p-color pt-4 m-0">We offer out-of-home advertising, branding and activation consultancy.</p>
                </div>
            </div>
            <div className="gallery-container">
                <Slider {...settings}>
                  { 
                    SliderData.length !== 0 &&
                    SliderData.map((image, index) =>{
                      return( 
                        
                        <div key={index} className="image-inner-wrapper">
                            <img className="images" src={image.images} alt="Digital out-of-home Advertising Agency in Nigeria" onClick={handleImgOnClick.bind(null, image.path)}/>
                            <div className="content-wrapper">
                            <h1>{image.title}</h1>
                            </div> 
                        </div>
                    
                      )
                    })
                  }
                </Slider>
            </div>
        </div>
    )
}

export default OurWorks;


