import React from 'react'
import { Link } from "react-router-dom";
export default function PageNotFound() {
  return (
    <div className='bg-white h-screen w-full flex justify-center items-center'>
        <div className='text-center font-medium'>
            <h1 className='text-6xl'>404</h1>
            <p  className='text-xl'>Ooops!!</p>
            <p>THAT PAGE DOESNT EXIST OR IS UNAVAILABLE.</p>
            <Link to="/home">
                <button className='bg-charity-color2 text-gray-50 rounded-md w-36 h-12 cursor-pointer border-2 border-charity-color2 hover:bg-white hover:text-charity-color2'>
                    Go Back to Home
                </button>
            </Link>
        </div>
    </div>
  )
}
