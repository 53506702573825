import  React from "react";
import LandingPage from "./Views/LandingPage";
import "./App.css";
import About from "./Views/About";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Gallery from "./Views/Gallery";
import Signin from "./Views/Signin";
import PasswordReset from "./Views/PasswordReset";
import Signup from "./Views/Signup";
import Blog from "./Views/Blog";
import GalleryUpdate from "./Views/GalleryUpdate";
import TeamView from "./Views/Team";
import ContactUs from "./Views/Contact";
import EditTeamView from "./Views/EditTeam";
import GalleryContextProvider from "./Utils/Context/galleryContext";
import TeamProfileContextProvider from "./Utils/Context/teamProfileContext";
import NominateCharityPage from "./Views/NominateCharityPage";
import CharityAdminPage from "./Views/CharityAdminPage";
import CharityContextProvider from "./Utils/Context/CharityContext";
import RankingPage from "./Views/RankingPage";
import VerificationPage from "./Views/VerificationPage";
import ConfirmVerificationPage from "./Views/ConfirmVerificationPage";
import ProtectedRoute from "./Utils/Auth/ProtectedRoute";
import CharityProtectedRoute from "./Utils/Auth/CharityProtectedRoute";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import PageNotFound from "./Views/PageNotFound";
import Services from "./Views/Services";


function App() {
  
  const setGA = () => {
    ReactGA.initialize('UA-227141078-1');
    ReactGA.pageview('Init page visit');
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  useEffect(()=>{
    setGA();
  }, []);

  return (
      <BrowserRouter >
        
          
          <TeamProfileContextProvider>
            <GalleryContextProvider>
            <CharityContextProvider>
            <Switch>
              <Route exact path="/about/:id"  component={About} />
              <Route path="/about" exact component={About} />
              <Route exact path="/signin" component={Signin} />
              <Route exact path="/admin" component={Signin} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/password-reset" component={PasswordReset} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/verify" component={VerificationPage}/>
              <Route path="/404" component={PageNotFound}/>
              <Route exact path="/" component={(props) =>{ return <LandingPage {...props} /> }}/>
              <Route path="/confirm-verification" exact component={ConfirmVerificationPage} />
              <Route path="/home" exact component={(props) =>{ return <LandingPage {...props} /> }}/>
              <CharityProtectedRoute path="/charity" exact component={ NominateCharityPage } />
              <Route path="/charity/:id" exact component={(props) =>{ return <NominateCharityPage {...props} /> }} />
              <ProtectedRoute path="/charity-admin" exact component={ CharityAdminPage }/>
              <ProtectedRoute path="/rank" exact component={ RankingPage } />
              <Route path="/team-profile" exact component={(props) =>{ return <TeamView {...props} /> }}/>
              <ProtectedRoute path="/edit-team" exact component={ EditTeamView } />
              <ProtectedRoute exact path="/gallery-update" component={ GalleryUpdate} />
              <ProtectedRoute path="/gallery-update/:id" exact  component={ GalleryUpdate } />
              <Route exact path="/gallery" component={(props) =>{ return <Gallery {...props} /> }}/>
              <Route path="/gallery/:id" exact  component={(props) =>{ return <Gallery {...props} /> }} />  
              <Route component={PageNotFound} />
              </Switch>
              </CharityContextProvider>
            </GalleryContextProvider>
          </TeamProfileContextProvider>
         
        
      </BrowserRouter>
  );
}

export default App;
