import React,{useContext} from 'react'
import NominateCharity from '../Components/Charity/NominateCharity'
import Footer from '../Components/Footer/Footer';

function NominateCharityPage() {

    
    const handleScrollToView = (currntView) =>{
        window.scrollTo(0,0);
    }
    return (
        <div className="body-wrapper">
            <NominateCharity handleScroll={handleScrollToView}/>
            <Footer footerBodyVisible={false} footerHeadVisible={true} posts={[]} blogFooterVisible={false}/>      
        </div>
        
    )
}

export default NominateCharityPage;
