import axios from 'axios';
import React, {useState, useEffect} from 'react'
import EntriesSecton from './EntriesSecton';
import ShuffledNominations from './ShuffledNominations';
import SortedEntry from './SortedEntry';
import TimerSection from './TimerSection';
import DeleteModal from "../GalleryUpdate/DeleteModal";

function CharityAdmin({ handleLoadingState }) {
    const round = localStorage.getItem("round");
    const [ sortedVotes,setsortedVotes ] = useState([]);
    const [ allVoters, setAllVoters ] = useState([]);
    const [ message, setMessage ] = useState({
        msg: "",
        status: ""
    });
    const [ imageIsDeleted, setImageIsDeleted ] = useState(false);

    const getSortedVotes = () =>{
        
        axios.get(`https://nimbus-media-staging.herokuapp.com/api/v1/charity/sorted?round=${round}`, { withCredentials: true})
        .then((res) =>{
            if(res.data.status === "success"){
                var response = res.data.data.stats
                if(response.length !== 0){
                   setsortedVotes(response.map(data =>(
                       data
                   )));
                }
            }
        })
        .catch(err =>{
            console.log(err) 
         })
        
    }
    
    const getAllVoters = () =>{
        
            axios.get(`https://nimbus-media-staging.herokuapp.com/api/v1/charity?round=${round}`, { withCredentials: true })
            .then((res) =>{
                if(res.data.data.stats.length !== 0){
                    setAllVoters(res.data.data.stats.map(data =>(
                        data
                    )))
                }
            })
            .catch(err =>{
                console.log(err);
            })
        
        
    }
    const closeBackDrop = () =>{
        setImageIsDeleted(false);
    }
    const handleClearAll = () =>{
        handleLoadingState(true);
        if(round !== null){
        axios.delete(`https://nimbus-media-staging.herokuapp.com/api/v1/charity?round=${round}`, {withCredentials:true})
        .then((res) =>{
            handleLoadingState(false);
            setMessage({
                msg: "Vote cleared successfully !!!",
                status: ""
            });
            setImageIsDeleted(true);
            setAllVoters([]);
            setsortedVotes([]);
        })
        .catch(err =>{
            setMessage({
                msg: err.response.data.message,
                status: "0"
            });
            handleLoadingState(false);
            setImageIsDeleted(true);
        })
        }
    }
    const handleModal = (message, status) =>{
        setMessage({
            msg: message,
            status: status
        });
        setTimeout(() => {
            setImageIsDeleted(true);
        }, 500);
    }
    
    useEffect(() => {
        getSortedVotes();
        getAllVoters();
        
    }, [])
    return (
        <div className="md:text-about-p-color">
            {
                imageIsDeleted &&
                <>
                    <div className="back-drop" onClick={closeBackDrop}>
                    </div>
                    <DeleteModal onClick={closeBackDrop} text={message} />
                </>
                
            }
            <div className="bg-gradient-to-b from-brand-color2 via-brand-color3 to-brand-color4">
                <div className="xl:w-9/12 w-4/5 mx-auto bg-background-logo bg-no-repeat bg-contain bg-top">
                    <h1 className="text-center font-black md:py-5 py-3 text-brand-color1 md:text-4xl text-2xl opacity-80">
                        Nominate a Charity
                    </h1>
                    <div className="block">
                       <TimerSection handleLoadingState={handleLoadingState} handleModal={handleModal}/>
                    </div>
                </div>
                <div className="w-9/12 m-auto">
                    <EntriesSecton allVotes={allVoters}/>
                    <SortedEntry sortedEntries={sortedVotes} handleLoadingState={handleLoadingState} handleClearAll={handleClearAll}/>
                    <ShuffledNominations sortedEntries={sortedVotes} handleLoadingState={handleLoadingState} handleModal={handleModal} />
                </div>
            </div>
        </div>
    )
}

export default CharityAdmin;
