import React,{ useState, useEffect} from 'react'
import axios from "axios";
import SectionHeader from './SectionHeader'

function EntriesSecton({allVotes}) {
    console.log(allVotes)
    // useEffect(() => {
    //     axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/charity?round=1", { withCredentials: true})
    //     .then((res) =>{
            
    //         if(res.data.status === "success"){
    //             console.log(res.data.data.stats)
    //             var response = res.data.data.stats
                
    //             setEntries((prevState)=>{
    //                 response.map((data) =>{
    //                     return{
    //                         data
    //                     }
    //                 })
    //             }
                    
    //             );
    //         }
    //     })
    //     .catch(err =>{
    //         console.log(err) 
    //      })
        
    // }, [])
    return (
        <div className="w-full pt-4 pb-5">
            <SectionHeader headerText="Entries" />
            {
                allVotes.length !== 0 ?
                <div className="w-full text-brand-color1">
                <table className="w-4/5 m-auto mt-4">
                    <tr className="bg-charity-color9 w-4/5">
                        <th className="opacity-80 font-bold py-1 pr-5">S/N</th>
                        <th className="opacity-80 font-bold py-1">Nominations</th>
                        <th className="opacity-80 font-bold py-1">Email address</th>
                        <th className="opacity-80 font-bold py-1">Name</th>
                    </tr>
                    {
                        allVotes.map((data, index) =>{
                            return(
                                <tr>
                                    <td className=" py-3 pl-3">{index + 1}</td>
                                    <td className="py-3">{data.charityName}</td>
                                    <td className="py-3">{data.email}</td>
                                    <td className="py-3">{data.name}</td>
                                </tr>
                            )
                        })
                    }
                   
                </table>
               
                </div>
                :
                <div className="py-16">
                    <p className="text-center text-brand-color1 font-bold text-2xl">Empty</p>
                </div>
            }
            
        </div>
    )
}

export default EntriesSecton;
