import React, {useContext, useState , useEffect } from "react";
import LandingPageHeader from "../Components/Header/LandingPageHeader";
import Gallery from "../Components/Gallery/LandingPageGallery";
import MissionVission from "../Components/Header/Mission_Vission";
import ContactUs from "../Components/Contact/ContactUs";
import Footer from "../Components/Footer/Footer";
import Charity from "../Components/Charity/Charity";
import LandingPageAbout from "../Components/LandingPageAbout/LandingPageAbout";
import CharityCountDown from "../Components/Charity/CharityCountDown";
import Header from "../Components/Header/Header";
import { GalleryContext } from "../Utils/Context/galleryContext";
import { TeamProfileContext } from "../Utils/Context/teamProfileContext";
import { Helmet } from "react-helmet-async";
import GetStartedBtn from "../Components/GetStartedBtn";
import OurWorks from "../Components/OurWorks/OurWorks";
import AboutUs from "../Components/About/AboutUs";

const LandingPage = () =>{
    const { gallery } = useContext(GalleryContext);
    const { countDownDetails } = useContext(TeamProfileContext);
    const [ btnIsEnabled, setBtnIsEnabled ] = useState(false);
    const [ countDownDetail,setCountDownDetail ] = useState({});
        
    const [ timerValue, setTimerValue] = useState({
        countDownWeek: 0,
        countDownDay: 0,
        countDownHour: 0,
        countDownMinute: 0,
        countDownSecond: 0
    })
    const getCountDown = () =>{
        if(countDownDetails.round === null){
            setBtnIsEnabled(true);
        }
        else{
            setBtnIsEnabled(false);
            const interval =  setInterval(CountDown.bind(null, countDownDetails) , 1000);
                    return(() =>{
                        clearInterval(interval);
                    })
        }
    }
    const CountDown = (details) =>{
        var date = new Date();
        var utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
        var timeOffset = 2;
        var now = new Date(utcTime + (3600000 * timeOffset)).getTime();
        var startDate = details.start;
        var endDate = details.stop;
        if(startDate !== '' || endDate !== '') {
            const end_date = new Date(endDate).getTime();
            const start_date = new Date(startDate).getTime();
            
            if(now >= start_date && now < end_date){
                
                const timeDifference = end_date - now;
                const second = 1000;
                const minute = second * 60;
                const hour = minute * 60;
                const day = hour * 24;
                const week = day * 7;
                setTimerValue({
                    countDownWeek: Math.floor(timeDifference/ week),
                    countDownDay: Math.floor(timeDifference  % week / (day)),
                    countDownHour: Math.floor(timeDifference % day /(hour)),
                    countDownMinute: Math.floor(timeDifference % hour /(minute)),
                    countDownSecond:Math.floor(timeDifference % minute /(second))
                });
                setBtnIsEnabled(false);

            }
            else{
                 setTimerValue({
                    countDownWeek: 0,
                    countDownDay: 0,
                    countDownHour: 0,
                    countDownMinute: 0,
                    countDownSecond:0
                })
                setBtnIsEnabled(true);
            }
        }
        else{
            setTimerValue({
                countDownWeek: 0,
                countDownDay: 0,
                countDownHour: 0,
                countDownMinute: 0,
                countDownSecond:0
            })
            setBtnIsEnabled(true);
        }
        
    }
    
    
    useEffect(() =>{
        window.scrollTo(0, 0);
        getCountDown();
        setCountDownDetail(countDownDetails);
    },[countDownDetails])
    return(
        <div className="body-wrapper">
            <div className="LandingPage" >
                <Helmet>
                    <title>Digital Out-of-Home Advertising Agency in Nigeria | NIMBUS MEDIA</title>
                    <meta name="description" content="We Are the Leading Digital Out-of-home Advertising Agency in Nigeria with Networks Nationwide and Proven Records across Digital OOH Media Platforms."/>
                </Helmet>
                <Header />
                <LandingPageHeader  />
                <GetStartedBtn path="/home"/>
                <MissionVission />
                <AboutUs />
                <OurWorks />
                <Charity countDownDetail={countDownDetails} btnState={btnIsEnabled} timerValue={timerValue}/> 
                <LandingPageAbout/>
                {/* <Gallery gallery={gallery}/> */}
                <ContactUs /> 
                <Footer footerBodyVisible={true} footerHeadVisible={true} />
            </div>
        </div>
    )
}

export default LandingPage;