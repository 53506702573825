import React from 'react';
import "./CharityCountDown.css";

function CharityCountDown({ timerValue }) {
   
    
    return (
            <div className="charity-countdown-container">
                <div className="charity-countdown-inner-container">
                    <div className="charity-countdown-header">
                        <h1>Next Nominate a Charity Contest</h1>
                    </div>
                    <div className="charity-countdown-content-container">
                        <div className="charity-countdown-wrapper">
                            <h1>{timerValue.countDownWeek}</h1>
                            <p>Weeks</p>
                        </div>
                        <div className="charity-vertical-line"></div>
                        <div className="charity-countdown-wrapper">
                            <h1>{timerValue.countDownDay}</h1>
                            {timerValue.countDownDay === 0 ?
                                <p>Day</p>:
                                <p>Days</p>
                            }
                        </div>
                        <div className="charity-vertical-line"></div>
                        <div className="charity-countdown-wrapper">
                            <h1>{timerValue.countDownHour}</h1>
                            <p>Hours</p>
                        </div>
                        <div className="charity-vertical-line"></div>
                        <div className="charity-countdown-wrapper">
                            <h1>{timerValue.countDownMinute}</h1>
                            <p>Mins</p>
                        </div>
                        <div className="charity-vertical-line"></div>
                        <div className="charity-countdown-wrapper">
                            <h1>{timerValue.countDownSecond}</h1>
                            <p>Seconds</p>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default CharityCountDown;
