import React, {useState , useEffect} from "react";
import logo from "../../assets/images/NIMBUS_OFFICIAL_LOGO.png";
import white_logo from "../../assets/images/NIMBUS_WHITE_LOGO.png";
import "./Header.css";
import { IoMdArrowDropdown,IoMdMenu } from "react-icons/io";
import { useLocation ,NavLink, Link as LINK, useHistory} from "react-router-dom";
import { MdClose } from "react-icons/md";

function AdminHeader() {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const [ isMenuActive , setIsMenuActive ] = useState(false);
    const [ headerStyle, setHeaderStyle] = useState({
        background: "#E5EFFF",
        padding: window.innerWidth <= 760 ? "0px 15px": "20px"
    })

    const MenuState = () =>{
       setIsMenuActive(!isMenuActive);
    }
    
     const handleOnScroll = () =>{
         if(window.scrollY > 0 && window.innerWidth >= 760){
            setHeaderStyle((prevState) =>{
                return {...prevState, padding: "0px"}
            })
         }
         else{
            setHeaderStyle((prevState) =>{
                return {...prevState, padding: "0px 15px"}
            })
         }
     }

     const handleLogout = () =>{
         localStorage.removeItem("token");
         history.push("/signin");

     }
   
      useEffect(()=>{
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleOnScroll);
        return(() =>{
            window.removeEventListener('scroll', handleOnScroll);
        })
      }, [])
    return (
        <div className="flex justify-evenly items-center sticky top-0 left-0 w-full z-50" style={headerStyle}>
                <div className="logo-wrapper">
                    <img alt="Nimbus Media" src={logo} /> 
                    <i onClick={MenuState} className={isMenuActive ? "icon activeNav"  : "icon"}>{<IoMdMenu />}</i>
                </div>
                <div className="side-nav-backdrop" style={{display: isMenuActive ? "block" : "none"}} onClick={MenuState}>
                </div>
                <div className= {isMenuActive ? "nav-wrapper activeNav"  : "nav-wrapper"}>
                    <ul className="header-nav-ul">
                        <li>
                            <LINK className={splitLocation[1] === "home" || "" ? "active" : "inactive"} to="/home">Home</LINK>
                        </li>
                       
                        <li>
                            <LINK className={splitLocation[1] === "gallery-update" ? "active" : ""} to="/gallery-update">Admin Gallery</LINK>
                        </li> 
                        <li>
                            <LINK className={splitLocation[1] === "edit-team" ? "active" : ""} to="/edit-team">Admin Profile</LINK>
                        </li>
                        <li>
                            <LINK className={splitLocation[1] === "charity-admin" ? "active" : ""} to="/charity-admin">Nominate a Charity</LINK>
                        </li>
                        <li>
                            <LINK className={splitLocation[1] === "rank" ? "active" : ""} to="/rank">Ranking system</LINK>
                        </li>
                        <li className="side-nav-signin-wrapper">
                            <NavLink activeClassName="" className="sign-in" to="/signin"><span>Logout</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="signin-wrapper">
                    <button activeClassName="" onClick={handleLogout} className="sign-in">Logout</button>
                </div>
        </div>

    )
}

export default AdminHeader
