import React,{ useEffect, useState,useContext } from 'react'
import "./Gallery.css";
import GalleryCard from './GalleryCard';
import PaginationComponent from '../Pagination';
import { Link as LINK, useHistory } from 'react-router-dom';
import ImageModal from './ImageModal';
import image_Not_Found from "../../assets/images/imageNotFound.png";
import SkeletonGallery from '../Skeletons/SkeletonGallery';
import { GalleryContext} from "../../Utils/Context/galleryContext";
import { AiOutlineClose } from 'react-icons/ai';

function Gallery(props) {
    const sections = ["branding", "activation", "digital", ""]
    const { gallery, isLoading } = useContext(GalleryContext);
    const [ sectionIsValid, setSectionIsValid ] = useState(false)
    const filterBy = props.section;
    const history = useHistory();
    const [ currentGallery, setCurrentGallery ] = useState([]);
    const [ currentPage, setCurrentPage] = useState(1);
    const [ postPerPage] = useState(9);
    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost  = indexOfLastPost-postPerPage;
    const currentPosts  = currentGallery.slice(indexOfFirstPost, indexOfLastPost);
    const  totalPosts = currentGallery.length;
    const [ backDropIsActive , setBackDropIsActive ] = useState(false);
    const [ id, setId ] = useState("");
    const [ imageIndex, setImageIndex ] = useState(0);
    const [ animate , setAnimation ] = useState(true);
    const [ imageNotFound,setImageNotFound ] = useState("");

    const handleSetPage = (number) =>{
        setCurrentPage(number);
        window.scrollTo(0, 0);
    }
    const handleSectionOnClick = () =>{
        setAnimation(true);
        setTimeout(()=>{
            setAnimation(false)
        } , 1000);
     }
     const filterGallery = (galleryData) =>{
        if(galleryData.category.toLowerCase() === filterBy){
            return galleryData; 
        }
        if(filterBy === undefined){
            return galleryData; 
        }
     }
     const closeBackDrop = () =>{
        setBackDropIsActive(false);
     }
     const handleImgOnClick = (index) =>{
        setImageIndex(index);
        setBackDropIsActive(true)
     }

     useEffect(() => {
         
         let isValid = false;
        sections.forEach(function(data){
            if(filterBy === data || filterBy === undefined){
                isValid = true;
            }
        })
        if(!isValid){
            history.push("/404");
        }
     }, [])

     useEffect(() => {
        window.scrollTo(0, 0);
        if(gallery.filter(filterGallery).length === 0){
            setImageNotFound(image_Not_Found);
        }
            setCurrentGallery(
                gallery.filter(filterGallery).map((data, index) =>{
                    return{
                        ...data,
                        index: index
                    }
                })
                );
            setCurrentPage(1);
      }, [filterBy, gallery])
    
    
    return (
        <div className="main-gallery-container">
            {backDropIsActive &&
                <div className="back-drop" onClick={closeBackDrop}>
                </div>
            }
            {backDropIsActive && 
                <>
                    <i className='fixed right-5 top-5 text-white text-3xl cursor-pointer z-75 hover:text-charity-color2' onClick={closeBackDrop}><AiOutlineClose /></i>
                    <ImageModal index={imageIndex} gallery={currentGallery}/>
                </>
            }
            <div className="main-gallery-header">
                <h1>Our Gallery</h1>
            </div>
            <div className="gallery-section-nav">
                <div className="gallery-section-inner-nav">
                    <div className="gallery-nav-wraper text-brand-color1 font-semibold">
                        <div className="text-2xl">
                            <LINK className={props.section === undefined ? "activeSection" : "inactive"} to="/gallery" onClick={handleSectionOnClick}>All</LINK>
                        </div>
                        <div>
                            <LINK className={props.section === "activation" ? "activeSection" : "inactive"} to="/gallery/activation" onClick={handleSectionOnClick} >Activation</LINK>
                        </div>
                        <div>
                            <LINK className={props.section === "branding" ? "activeSection" : "inactive"} to="/gallery/branding" onClick={handleSectionOnClick} >Branding</LINK>
                        </div>
                        <div>
                            <LINK className={props.section === "digital" ? "activeSection" : "inactive"} to="/gallery/digital"onClick={handleSectionOnClick}>Digital</LINK>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery1-slider-container">
                <div className={`w-4/5 m-auto ${animate ? "gallery-image-wrapper" : ""}`}>
                
                        {/* <img className="image-not-found" src={imageNotFound} alt="image_notfound" />
                        : */}
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                        
                        {isLoading &&
                            [1,2,3,4,5,6].map((n,index) =>{ 
                                return( 
                                    <SkeletonGallery key={n} theme="light"/>
                            )})
                        } 
                        {
                        currentPosts.length !== 0 &&
                           currentPosts.map((images ,index) =>{
                                return(
                                    <GalleryCard galleryImg={images.imageUrl} title={images.category} id={images._id} deleteBtnActive={false} handleImgOnClick={handleImgOnClick.bind(null, images.index)}/>    
                                )
                            })
                        }
                        </div>
                        {/* {
                            currentGallery.length === 0 &&
                            <div className='text-center'>
                                <img src={imageNotFound} alt="no_image" className="w-52 h-52 object-contain" />
                            </div>
                        }
             */}
                </div>
                </div>
            <div className="main-gallery-nav">
                <PaginationComponent numOfPosts={totalPosts}  handleSetPage={handleSetPage}/>
            </div>
        </div>
    )
}

export default Gallery;
