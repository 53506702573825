import React,{ useEffect,useState} from 'react'
import Header from '../Components/Header/Header';
import Team from '../Components/Team/Team';
import Axios from "axios";
import Loader from '../Components/Loader/Loader';
import GetStartedBtn from '../Components/GetStartedBtn';

function TeamView() {
    
    const [ posts, setPosts ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        Axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nimbusmedia")
        .then(res =>{
            setLoading(true);
            setPosts(res.data.items);
            
        })
     }, [])
    
    return (
        <div className="body-wrapper">
            <div className="team-profile-wrapper">
                <Header />
                    <Team>
                        <Loader color="#FFFFFF" /> 
                    </Team>
            </div>
            <GetStartedBtn />
        </div>
    )
}

export default TeamView;
