import React from 'react'
import { Redirect, Route } from 'react-router-dom'

function ProtectedRoute({ component: Component, ...rest}) {
    const token = localStorage.getItem("token");
    return (
       <Route 
        {...rest}
           render={(props) =>{
            if(token !== null){
                return(
                    <Component {...props} />
                )
            }
            else{
                return(
                    <Redirect  to={{pathname:"/admin", state: { from: props.location }}}/>
                )
            }
           }}
       />
    )
}

export default ProtectedRoute
