import { PostAddSharp } from '@material-ui/icons';
import {React} from 'react';
import "./Blog.css";
import Post from './Post';
import imageNotFound from "../../assets/images/imageNotFound.png";
import SkeletonBlog from '../Skeletons/SkeletonBlog';
import { Link } from "react-router-dom";

function Blog(props) {
    return (
        <div className="blog-container">
            <div className="blog-header-text">
                <h1>Blog & Press</h1>
            </div>
            <div className="blog-inner-container">
            {
                props.posts.length !== 0 ?
                props.posts.map((post) =>{
                    return(
                        <div className="blog-outer-slider-img hover:scale-50">
                            <Link target="_blank" to={{  pathname: post.link }} rel="noopener noreferrer" className='no-underline'>
                                <Post img={post.thumbnail} title={post.title} content={post.content.slice(0 ,600).replace(/<.*>/g,"") + "..."} author={post.author} link={post.link} date={post.pubDate}/>
                            </Link>
                            
                        </div>
                    )
                })
                :
                    [1,2,3].map((n,index) =>{
                        return(
                            <div className="blog-outer-slider-img">
                                <SkeletonBlog key={n} theme="light"/>
                            </div>
                        )
                    })
            }
            </div>
        </div>
    )
}

export default Blog;
