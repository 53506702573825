import React,{useState,useEffect, useContext} from 'react'
import Header from '../Components/Header/Header';
import Gallery from '../Components/Gallery/Gallery';
import Footer from '../Components/Footer/Footer';
import { GalleryContext } from '../Utils/Context/galleryContext';
import Loader from '../Components/Loader/Loader';
import GetStartedBtn from '../Components/GetStartedBtn';

function GalleryPage(props) {
    
    return (
        <div className="gallery-page">
            <Header />
            {
            
                <div className="body-wrapper">
                    <Gallery section={props.match.params.id}>
                        {/* <Loader color="#FFFFFF" /> */}
                    </Gallery>
                    <Footer footerBodyVisible={true} footerHeadVisible={true}/>      
                </div>
            }
            <GetStartedBtn />
        </div>
    )
}

export default GalleryPage;
