import React from 'react'
import Footer from '../Components/Footer/Footer';
import SigninFooter from '../Components/Footer/SigninFooter';
import Header from '../Components/Header/Header';
import Signin from '../Components/Signin/Signin';

function SigninPage() {
    return (
        <div className="body-wrapper">
            <div>
                <Signin />
            </div>
        </div>
    )
}

export default SigninPage
