import React from 'react';
import { useEffect } from 'react';
import ContactUs from '../Components/Contact/ContactUs';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';

function Contact() {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="body-wrapper">
            <div className="contact">
                <Header />
                <ContactUs />
                <Footer footerBodyVisible={true} footerHeadVisible={false} posts={[]} blogFooterVisible={false}/>
            </div>
        </div>
    )
}
export default Contact;
