import React, {useState} from 'react';
import CharityAdmin from '../Components/Charity/CharityAdmin';
import Footer from '../Components/Footer/Footer';
import AdminHeader from '../Components/Header/AdminHeader';
import Loader from "../Components/Loader/Loader";

function CharityAdminPage() {
    const [ loading, setLoading ] = useState(false);
    const handleLoadingState = (state) =>{
        setLoading(state);
    }
    return (
        <>
            {
                loading && <Loader color={"#FFFFFF"}/>
            }
            <div className="body-wrapper">
        
            <AdminHeader />
            <CharityAdmin handleLoadingState={handleLoadingState}/>
            <Footer footerBodyVisible={false} footerHeadVisible={true} posts={[]} blogFooterVisible={false}/>      
        </div>
        </>
        
    )
}

export default CharityAdminPage
