import axios from 'axios';
import React, { useState, useEffect } from 'react';
import SectionHeader from './SectionHeader';

function ShuffledNominations({ handleLoadingState, handleModal }) {

    const [ message, setMessage ] = useState({
        message: "",
        status: ""
    });
    const [ topNominees, setTopNominees ] = useState({
        first: "",
        second: "",
        third: "",
        id: null
    });
    const handleOnsubmit = (e) =>{
        e.preventDefault();
        addNominees(topNominees);
    }
    const getTopNominatons = () =>{
        handleLoadingState(true);
        axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/three")
        .then((res) =>{
            handleLoadingState(false);
            console.log(res)
            if(res.data.data.entry.length !== 0){
                const [object] = res.data.data.entry;
                setTopNominees({
                    first: object.first,
                    second: object.second,
                    third: object.third,
                    id: object._id
                });
            }
            else{
                setTopNominees({
                    first: "",
                    second: "",
                    third: "",
                    id: null
                })
            }
        })
        .catch(err =>{
            handleLoadingState(false);
        })
    }
    
    const handleOnChange = (e) =>{
        e.preventDefault();
        setTopNominees((prevState) =>{
            return{
                ...prevState,
                [e.target.name] : e.target.value
            }
        })
    }
    const addNominees = (payload) =>{
        handleLoadingState(true);
        axios.post("https://nimbus-media-staging.herokuapp.com/api/v1/three", payload, { withCredentials: true })
        .then((res) =>{
            if(res.data.status){
                handleLoadingState(false);
                handleModal("Nominees has been set !!","");
                getTopNominatons();
            }
        })
        .catch(err =>{
            handleLoadingState(false);
            handleModal(err.response.data.message,"0");
        })
    }
    const handleDelete = (id) =>{
        if(id !== null) {
        handleLoadingState(true);
        axios.delete(`https://nimbus-media-staging.herokuapp.com/api/v1/three/${id}`, {withCredentials:true})
        .then((res) =>{
            handleLoadingState(false);
            handleModal("Nominations Deleted Successfully !!!","");
            getTopNominatons();
        })
        .catch(err =>{
            handleLoadingState(false);
            handleModal(err.response.data.message,"0");
        })
        }
    }
   
    useEffect(() => {
        getTopNominatons();
    }, [])
    return (
        <div className="w-full pt-4 pb-5">
            <SectionHeader headerText="Shuffled Nomination" />
            <p className={`${message.status === "0" ? "bg-red-300 text-red-600 py-2 my-2": ""} ${message.status === "1" ? "bg-green-200 text-green-600 py-2 my-2": ""} pl-6`}>{message.message}</p>
                <div className="w-4/5 m-auto text-brand-color1">
                    <form onSubmit={handleOnsubmit}>
                    <table className="w-full mt-4">
                        <tr className="bg-charity-color9 w-full">
                            <th className="opacity-80 font-bold py-1 max-w-50 min-w-50">Enter names of top nominees</th>
                        </tr>
                            <tr>
                                <td className=" py-3 pl-3">1<input className="ml-2 border border-charity-color4 placeholder-charity-color4 w-80 py-1 pl-1" name="first" onChange={handleOnChange} placeholder="Type name here" value={topNominees.first} required/></td>
                            </tr>
                            <tr>
                                <td className=" py-3 pl-3">2<input className="ml-2 border border-charity-color4 placeholder-charity-color4 w-80 py-1 pl-1" name="second" onChange={handleOnChange} placeholder="Type name here" value={topNominees.second} required/></td>
                            </tr>
                            <tr>
                                <td className=" py-3 pl-3">3<input className="ml-2 border border-charity-color4 placeholder-charity-color4 w-80 py-1 pl-1" name="third" onChange={handleOnChange} placeholder="Type name here" value={topNominees.third} required/></td>
                            </tr>
                    </table>
                   
                    <div className="py-10 w-4/5">
                    {
                        topNominees.id === null ?
                        <button type="submit" className="w-32 py-2 rounded-full border-2 bg-brand-color1 text-white border-brand-color1 hover:text-brand-color1 hover:bg-charity-color8 font-semibold text-lg mr-5">Save</button>
                        :
                        <button type="button" className="w-32 py-2 rounded-full border-2 border-charity-color5 text-charity-color5 hover:bg-charity-color5 hover:text-white font-semibold text-lg" onClick={handleDelete.bind(null,topNominees.id)}>Clear all</button>
                    }
                </div>
                </form>
                </div>
        </div>
    )
}

export default ShuffledNominations;
