import { React, createContext, useState, useEffect} from "react";
import axios from "axios";

export const TeamProfileContext = createContext({});
const TeamProfileContextProvider = (props) =>{
    const teamProfileApi = process.env.REACT_APP_TEAM_PROFILE_API;
   const teamPositionsApi = process.env.REACT_APP_GET_TEAM_POSITION_API;
   const [ countDownDetails,setCountDownDetails ] = useState({
    round: null,
    start: "",
    stop: ""
    });
    // const[ message, setMessage ] = useState({
    //     status: null,
    //     msg: ""
    // });
    const [ countDownDetailsId, setCountDownDetailsId ] = useState("");
    const [ teamProfile, setTeamProfile ] = useState([]);
    const [ rankMessage, setRankMessage ] = useState({
        message: "",
        status: ""
    });
    const [ ranks, setRanks ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const getTeamProfile = () =>{
        setIsLoading(true)
        axios.get(teamProfileApi)
        .then((response) =>{
            if(response.data.status === "success"){
            setTeamProfile(response.data.data.images);
            setIsLoading(false);
            }
        })
        .catch((err) =>{
            console.log(err);
        })
    }
    const createProfile = (data) =>{
        setIsLoading(true)
        axios.post(teamProfileApi,data,{withCredentials: true})
        .then((response) =>{
            setIsLoading(false);
            updateRankMessage("Profile Successfully Created!!", "1");
            getTeamProfile();
        })
        .catch((err) =>{
            setIsLoading(false);
            updateRankMessage(err.response.data.message, "0");
        })
    }
    const editProfile = (id,data) =>{
        setIsLoading(true);
        axios.patch(teamProfileApi + "/" + id,data,{withCredentials: true})
        .then((response) =>{
            setIsLoading(false);
            updateRankMessage("Profile Successfully Created!!", "1");
            getTeamProfile();
        })
        .catch((err) =>{
            setIsLoading(false);
            updateRankMessage(err.response.data.message, "0");
        })
    }
    const deleteProfile = (id) =>{
        setIsLoading(true);
        axios.delete(teamProfileApi +"/" + id, {withCredentials: true})
        .then((response) =>{
            setIsLoading(false);
            getTeamProfile();
        })
        .catch((err) =>{
            setIsLoading(false);
            console.log(err);
        })
    }

    const getTeamPositions = () =>{
        setIsLoading(true);
        axios.get(teamPositionsApi, {withCredentials: true})
        .then((response) =>{
            if(response.data.status === "success"){
            setIsLoading(false);
            setRanks(response.data.data.ranks);
            }
        })
        .catch((err) =>{
            setIsLoading(false);
            console.log(err);
        })
    }

    const createTeamPositions = (payload) =>{
        setIsLoading(true);
        axios.post(teamPositionsApi, payload, {withCredentials: true})
        .then((response) =>{
            updateRankMessage("Rank Successfully Created!!", "1");
            getTeamPositions();
            setIsLoading(false);
        })
        .catch((err) =>{
            setIsLoading(false);
            updateRankMessage("Duplicate Fields!!!", "0");
        })
            updateRankMessage("", "");
    }
    const deleteTeamPositions = (id) =>{
        setIsLoading(true);
        axios.delete(teamPositionsApi +"/" + id, {withCredentials: true})
        .then((response) =>{
            setIsLoading(false);
            getTeamPositions();
        })
        .catch((err) =>{
            setIsLoading(false);
            console.log(err);
        })
    }
    const createCountDownConfiguration = (paylod) =>{
        setIsLoading(true);
        axios.post("https://nimbus-staging.onrender.com/api/v1/date", paylod,{withCredentials: true})
        .then((res) =>{
            if(res.data.status === "success"){
                setIsLoading(false);
                localStorage.setItem("round", paylod.round);
                if(res.data.data.entry.length === 0){
                    setCountDownDetails((prevState) =>{
                        return{
                            round: null,
                            start: "",
                            stop: ""
                        }
                    })
                }
                else{
                    console.log(paylod.round)
                    localStorage.setItem("round",paylod.round);
                    localStorage.setItem("countDownId",res.data.data.entry._id);

                    setCountDownDetailsId(res.data.data.entry._id);
                    setCountDownDetails((prevState) =>{
                        return{
                            ...prevState,
                            round: res.data.data.entry.round,
                            start: res.data.data.entry.start,
                            stop: res.data.data.entry.stop
                        }
                    })
                }
            }
        })
        .catch((err) =>{
            setIsLoading(false);
            console.log(err.res);
        })
    }
    const getCountDownConfiguration = () =>{
        setIsLoading(true);
        axios.get("https://nimbus-staging.onrender.com/api/v1/date")
        .then((res) =>{
            if(res.data.status === "success"){
                setIsLoading(false);
                if(res.data.data.entry.length === 0){
                    setCountDownDetails((prevState) =>{
                        return{
                            round: null,
                            start: "",
                            stop: ""
                        }
                    })
                }
                else{
                    localStorage.setItem("countDownId",res.data.data.entry[0]._id );
                    const details = {
                            round: res.data.data.entry[0].round,
                            start: res.data.data.entry[0].start,
                            stop: res.data.data.entry[0].stop
                    }
                    setCountDownDetails((prevState) =>{
                        return{
                            ...details
                        }
                    })
                }
            }
            
        })
        .catch(err =>{
            setIsLoading(false);
        })
    }
   

    const deleteCountDownConfiguration = (id) =>{
        setIsLoading(true);
        axios.delete(`https://nimbus-staging.onrender.com/api/v1/date/${id}`, {withCredentials: true})
        .then((res) =>{
            setIsLoading(false);
            localStorage.removeItem("round");
            localStorage.removeItem("countDownId");
            getCountDownConfiguration();
           
        })
        .catch(err =>{
            setIsLoading(false);
        })
    }

    const updateRankMessage = (message, status) => {
        setRankMessage({
            message: message,
            status: status
        });
        setTimeout(() => {
            setRankMessage({
                message: "",
                status: ""
            });
        }, 5000);
    }
    useEffect(() => {
        getTeamProfile();
        getTeamPositions();
        getCountDownConfiguration();
    }, []);

    return(
        <TeamProfileContext.Provider value={{ isLoading, ranks,countDownDetails, getCountDownConfiguration, countDownDetailsId, deleteCountDownConfiguration,createCountDownConfiguration, rankMessage, updateRankMessage, createTeamPositions, deleteTeamPositions,getTeamPositions, teamProfile, deleteProfile, createProfile, editProfile}}>
            {props.children}
        </TeamProfileContext.Provider>
    )
}
export default TeamProfileContextProvider;

