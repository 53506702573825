import React, {useState, useRef, useEffect} from 'react'
import "./ImageModal.css";
import Slider from "react-slick";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";

function ImageModal(props) {
    const sliderRef = useRef();
    const [ gallery, setGallery ] = useState([])
    const [ slideIndex, setSlideIndex ] = useState({
        oldSlide: 0,
        activeSlide: 0
    })
    const handleNextClick = () => sliderRef?.current?.slickNext();
    const handlePrevClick = () => sliderRef?.current?.slickPrev();
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className={`forward-arrow-container ${slideIndex.activeSlide === props.gallery.length - 1 && "pointer-events-none opacity-25" }`} onClick={handleNextClick} onKey>
                <i className="image-nav" >{<IoIosArrowForward />}</i>
            </div>
        );
    }
          
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div className={`backward-arrow-container ${slideIndex.activeSlide === 0 && "pointer-events-none opacity-25"}`} onClick={handlePrevClick}>
                <i className="image-nav" >{<IoIosArrowBack />}</i>
            </div>
        );
    }
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "60px",
        nextArrow: <SampleNextArrow gallery={gallery}/>,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) =>
            setSlideIndex({
                oldSlide: current,
                activeSlide: next
            })
    }
    
    useEffect(() =>{
        setGallery(props.gallery);
        sliderRef.current.slickGoTo(props.index);
        const track = document.addEventListener('keydown', function(e) {
            if (e.code === 'ArrowLeft') {
                handlePrevClick();
            }
            else if (e.code === 'ArrowRight') {
                handleNextClick();
            }
        });
        return () => document.removeEventListener('keydown', track);
    },[]);
    return (
        <div className='h-420 image-modal'>
                <Slider ref={sliderRef}  {...settings}>
                    { 
                        gallery !== 0 &&
                        gallery.map((data) =>{
                            return(
                                <img src={data.imageUrl} className="object-contain" alt="backdrop" />
                            )
                        })
                    }
                </Slider>
        </div>
    )
}

export default ImageModal
