import React, {useState, useEffect, useContext} from 'react'
import SectionHeader from '../Charity/SectionHeader';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import {TeamProfileContext} from "../../Utils/Context/teamProfileContext";
import UpdateRank from './UpdateRank';

function ListOfRanks({ rankingList }) {
    const [ backDropIsActive , setBackDropIsActive ] = useState(false);
    const { ranks, createTeamPositions, isLoading, deleteTeamPositions } = useContext(TeamProfileContext);
    const [ deletebackDropIsActive , setDeletebackDropIsActive ] = useState(false);
    const [ editbackDropIsActive , setEditbackDropIsActive ] = useState(false);
    const [ id, setId] = useState("");
    
    const handleDelete = () =>{
        deleteTeamPositions(id);
        setBackDropIsActive(false);
        setDeletebackDropIsActive(false);
    }
    const handleOnDelete = (id) =>{
        setBackDropIsActive(true);
        setDeletebackDropIsActive(true);
        setId(id);
    }
    
    const handleOnEdit = (id) =>{
        setEditbackDropIsActive(true);
        setBackDropIsActive(true);
        setId(id);
    }

    const handleModalClose = () =>{
        setEditbackDropIsActive(false);
        setDeletebackDropIsActive(false);
        setBackDropIsActive(false);
    }

    const DeleteBackDrop = ({ handledelete, handleClose}) =>{
        return(
            <div className="delete-img-alert">
                <div className="delete-img-alert-wrapper">
                    <p>Are you sure you want to delete this profile</p>
                    <div className="delete-img-alert-btn">
                        <button className="yes-btn" onClick={handledelete}>Yes</button>
                        <button className="no-btn" onClick={handleClose}>No</button>
                    </div>
                </div>
            </div>
        )
    }
    useEffect(() => {
       
    }, [])
    return (
        <>
        { backDropIsActive &&
            <div className="back-drop" onClick={handleModalClose}></div>
        }
        {deletebackDropIsActive &&
            <DeleteBackDrop handledelete={handleDelete} handleClose={handleModalClose} />
        }
        {
            editbackDropIsActive &&
            <UpdateRank id={id} handleClose={handleModalClose} />
        }
        <div className="w-full pt-4 pb-5">
        <SectionHeader headerText="Hierarchical Rankings" />
        <div className="w-4/5 m-auto text-brand-color1">
           {
            ranks.length !== 0 ?
            <table className="w-full mt-4">
                <tr className="bg-charity-color9">
                    <th className="opacity-80 font-bold py-1 w-10 ">S/N</th>
                    <th className="opacity-80 font-bold py-1 w-3/12">Position</th>
                    <th className="opacity-80 font-bold py-1  w-10">Rank</th>
                    <th className="opacity-80 font-bold py-1  w-10"></th>
                    <th className="opacity-80 font-bold py-1  w-10"></th>
                </tr>
                {
                    ranks.map((data, index) =>{
                        return(
                            <tr>
                                <td className=" py-3 pl-3">{index+1}</td>
                                <td className="py-3">{data.position}</td>
                                <td className="py-3">{data.priority}</td>
                                <td className="py-3">
                                    <button className="hover:text-charity-color4 text-charity-color8"  onClick={handleOnDelete.bind(null, data._id)}>{<MdDelete />}</button>
                                </td>
                                <td className="py-3">
                                    <button className="hover:text-charity-color4 text-charity-color8"  onClick={handleOnEdit.bind(null, data._id)}>{<MdModeEdit />}</button>
                                </td>
                            </tr>
                        )
                    }) 
                }
               
            </table> :
            <p className='text-center py-20 font-bold'>Empty</p>
        }
            {/* <div className="py-10 w-4/5 m-auto">
                <button className="py-1 px-3 rounded-full border-2 bg-brand-color1 text-white border-brand-color1 hover:text-brand-color1 hover:bg-charity-color8 font-semibold text-lg mr-5">Add New Position</button>
                <button className="w-32 py-1 rounded-full border-2 border-brand-color1 text-brand-color1 hover:bg-charity-color8 hover:text-white font-semibold text-lg mr-5">Save</button>
                <button className="w-32 py-1 rounded-full  border-brand-color1 text-brand-color1 hover:bg-charity-color8 hover:text-white font-semibold text-lg">Clear all</button>
            </div> */}
        </div>
    </div>
    </>
    )
}
export default ListOfRanks
