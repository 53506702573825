import React,{useState} from 'react'
import { Pagination } from 'react-bootstrap';

function PaginationComponent(props){
    const [postPerPage] = useState(9);
    
    const onClick = (number) =>{
        props.handleSetPage(number);
    }
    let items = [];
    for (let number = 1; number <= Math.ceil(props.numOfPosts / postPerPage); number++) {
        items.push(
            <Pagination.Item key={number} active={number === props.currentPage} onClick={onClick.bind(null,number)}>
            {number}
            </Pagination.Item>
        );
    }
        return (
            props.numOfPosts > postPerPage && <Pagination>{items}</Pagination>
            
       )
    }

export default PaginationComponent;
