import React, {useState,useContext,useEffect} from 'react'
import TeamImage from './TeamImage';
import {profileData} from "./profileData";
import "./EditTeam.css";
import TeamBio from './TeamBio';
import AddTeam from './AddTeam';
import { IoIosPersonAdd } from 'react-icons/io';
import emptyImage from "../../assets/images/emptyImage.png";
import {TeamProfileContext} from "../../Utils/Context/teamProfileContext";
import UpdateTeam from './UpdateTeam';
import imageNotFound from "../../assets/images/imageNotFound.png";
import axios from 'axios';
import DeleteModal from '../GalleryUpdate/DeleteModal';
import SkeletonEditProfile from "../Skeletons/SkeletonEditProfile";

function EditTeam(props) {
    const [ imageId, setImageId ] = useState("");
    const [ backDropIsActive , setBackDropIsActive ] = useState(false);
    const [ addTeamIsActive , setAddTeamIsActive ] = useState(false);
    const [ teamBioIsActive , setTeamBioIsActive ] = useState(false);
    const [ editBackDropIsActive , setEditBackDropIsActive ] = useState(false);
    const [ deletebackDropIsActive , setDeletebackDropIsActive ] = useState(false);
    const [ notificationModal, setNotificationModal ] = useState(false);
    const [ notificationText, setNotificationText ] = useState("");
    const { createProfile } = useContext(TeamProfileContext);
    const [ image, setImage ] = useState(props.image);
    const [ teamPositions, setTeamPositions ] = useState([]);
    const [ teamProfile, setTeamProfile ] = useState({
        bio:"",
        name: "",
        position: "",
        photo: null
    });
    const [ file, setFile ] = useState();

    const handleFormOnSubmit = (event) =>{
        event.preventDefault();
        const formData = new FormData();
        formData.append('photo',file,file.name);
        formData.append('name',teamProfile.name);
        formData.append('bio',teamProfile.bio);
        formData.append('position',teamProfile.position);
        createProfile(formData);
    }
    const handleInputOnChange = (event) =>{
        if(event.target.name !== "photo"){
            setTeamProfile((prevData) =>{
                return { ...prevData, [event.target.name] : event.target.value }
            })
        }
        else{
            if(event.target.files && event.target.files[0]){
                setFile(event.target.files[0])
                const reader = new FileReader();
                reader.onload = (e) =>{
                    setImage(e.target.result);
                }
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    }
    const handleOnDragOver = (event) =>{
        event.preventDefault();
        event.stopPropagation();
    }
    const handleProfileOnClick = (id) =>{
        setImageId(id);
        setBackDropIsActive(true);
        
    }
    const handleProfileOnClose = () =>{
        setBackDropIsActive(false);
        setTeamBioIsActive(false);
        setAddTeamIsActive(false);
        setDeletebackDropIsActive(false);
       
    }
    console.log(props.profileData);
    const handleBioOnClick = (id) =>{
        setImageId(id);
        setTeamBioIsActive(true);
        setBackDropIsActive(true);
    }
    const handleBioOnClose = () =>{
        setTeamBioIsActive(false);
        setBackDropIsActive(false);
       
    }
    const handleTeamOnAdd = () =>{
        setAddTeamIsActive(true);
        setBackDropIsActive(true);
    }
    const handleTeamOnClose = () =>{
        setAddTeamIsActive(false);
        setBackDropIsActive(false);
    }
    
    const handleDeleteOnClick = (id) =>{
        setImageId(id);
        setDeletebackDropIsActive(true);
        setBackDropIsActive(true);
    }
    const handleDeleteOnClose = () =>{
        setDeletebackDropIsActive(false);
        setBackDropIsActive(false);
    }
    const handleNotificationOnClose = () =>{
        setNotificationModal(false);
        setBackDropIsActive(false);
    }
    const handleEditOnClick = (id) =>{
        setImageId(id);
        setBackDropIsActive(true);
        setBackDropIsActive(true);
    }
    const handleEditOnClose = () =>{
        setBackDropIsActive(true);
    }
    const handleDelete = () =>{
        props.onDelete(imageId);
        setDeletebackDropIsActive(false);
        setBackDropIsActive(false);
    }

    const handleNotificationModal = (text) =>{
        setNotificationModal(true);
        setNotificationText(text);
    }
    useEffect(() => {
        axios.get("https://nimbus-media-staging.herokuapp.com/api/v1/rank",{withCredentials: true})
        .then((res) =>{
            setTeamPositions(res.data.data.ranks);
        })
        .catch((err) =>{
            console.log(err);
        })
    }, [])
   
    return (
        <div className="team-container">
            { backDropIsActive &&
                <div className="back-drop" onClick={handleProfileOnClose}></div>
            }
            {
                notificationModal && <DeleteModal onClick={handleNotificationOnClose} text={notificationText} />
            }

            {deletebackDropIsActive &&
                <div className="delete-img-alert">
                    <div className="delete-img-alert-wrapper">
                        <p>Are you sure you want to delete this profile</p>
                        <div className="delete-img-alert-btn">
                            <button className="yes-btn" onClick={handleDelete}>Yes</button>
                            <button className="no-btn" onClick={handleDeleteOnClose}>No</button>
                        </div>
                    </div>
                </div>
            }
            {
                teamBioIsActive && 
                   props.profileData.map((data) =>{
                        return(
                           imageId === data._id && 
                            <UpdateTeam 
                            id={data._id}
                            onClick={handleBioOnClose} 
                            image={data.imageUrl} 
                            nameValue={data.name}
                            positionValue={teamPositions}
                            bioValue={data.bio}
                            />
                                )
                            })
            }
            {
                addTeamIsActive && 
                <AddTeam 
                    onClick={handleTeamOnClose} 
                    image={emptyImage} 
                    nameValue=""
                    positionValue={teamPositions}
                    bioValue=""
                    handleNotificationModal={handleNotificationModal}
                    />
            }

            <div className="team-header-container">
                <div className="team-header">
                    <h1>Edit Team Profile</h1>
                </div>
                <div className="edit-team-profile-btn">
                    <button onClick={handleTeamOnAdd}><span><IoIosPersonAdd /></span>Add Team</button>
                </div>
            </div>
            <div className="team-profile-frame">
                {
                    props.profileData.length !== 0 ?
                    props.profileData.map((data) =>{
                        return(
                            <div key={data._id} className="team-img-frame">
                                <TeamImage image={data.imageUrl} position={data.position} name={data.name} onClick={null} onDelete={handleDeleteOnClick.bind(null, data._id)} onEdit={handleBioOnClick.bind(null, data._id)} profileEdit={true}/>
                            </div>
                        )
                    }) :
                    [1,2,3,4,5,6].map((n) => {
                        return(
                            <div className="team-img-frame">
                                <SkeletonEditProfile theme="light"/>
                            </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}

export default EditTeam;
