import React from 'react';

function TeamHeader() {
    return (
        <div className="team-header-container">
            <div className="team-header">
                <h1>Our Team</h1>
                <p>We are a team of experts working collaboratively to empower you with opportunities to reach your customers and grow your bottom line.</p>
            </div>
        </div>
    )
}

export default TeamHeader;
