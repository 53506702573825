import React from 'react'

function DeleteModal({ text, onClick }) {
    return (
        <>
            <div className="back-drop" onClick={onClick}>
            </div>
            <div className="delete-img-alert">
            <div className="delete-img-alert-wrapper">
                <p className={text.status === "0" && "text-red-500"}>{text.msg}</p>
                <div className="delete-img-alert-btn">
                    <button className="ok-btn" onClick={onClick}>Ok</button>
                </div>
            </div>
            </div>
        </>
        
    )
}

export default DeleteModal;
