
import React from 'react';
import { useHistory } from 'react-router';
import "./Post.css";

function Post(props) {
    const pubDate = props.date;
    const unformatedDate = new Date(pubDate).toString().split(" ");
    const date = `${unformatedDate[1]} ${unformatedDate[2]} ${unformatedDate[3]}`
    
    return (
        <div className="post-wrapper hover:opacity-75">
            <div className="post-img-wrapper">
                <img src={props.img} alt={props.title} className="object-cover"/>
            </div> 
            <div className="post-content-wrapper">
            <div className="post-header">
                <h1 className="post-title">{props.title}</h1>
                <div className="author-wrapper">
                    <div className="post-author">
                        <p>by<span className="pl-2">{props.author}</span></p>
                    </div>
                    <div className="post-date">{date}</div>
                </div>
            </div>
            <div className="post-body">
                {props.content}
            </div>
            <div className="post-footer">
                <div>
                    <p className="hover:text-brand-color1">Read More </p>
                </div>
            </div>
            </div> 
        </div>
    )
}

export default Post
