import React,{useContext, useEffect, useRef, useState} from 'react'
import NominateCharity from '../Components/Charity/NominateCharity'
import VerificationPage from "../Views/VerificationPage";
import { useHistory } from "react-router-dom";
import { TeamProfileContext } from "../Utils/Context/teamProfileContext";
import Verification from '../Components/Charity/Verification';

function ConfirmVerificationPage() {
    const [ view , setView ] = useState({}) ;
    const { countDownDetails } = useContext(TeamProfileContext);
    const handleScrollToView = (currntView) =>{
        setView(currntView);
        if(currntView){
            currntView.current.scrollIntoView();
        }
    }
    useEffect(() => {
        if(view){
            handleScrollToView();
        }
        
    }, [view])

    return (
        <div>
           <NominateCharity countDownDetails={countDownDetails} handleScroll={handleScrollToView}>
                <Verification
                    icon={
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    }
                    messageHeader="Vote Validated Successfully !!!"
                    btnText="Ok"
                    btnText1=""
                    errorMessage={null}
                    btnActive={true}
                />
           </NominateCharity> 
        </div>
    )
}

export default ConfirmVerificationPage
