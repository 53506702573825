import React,{useContext, useEffect,useState} from 'react'
import Header from '../Components/Header/Header';
import Axios from "axios";
import Footer from '../Components/Footer/Footer';
import EditTeam from '../Components/Team/EditTeam';
import AdminHeader from '../Components/Header/AdminHeader';
import { TeamProfileContext } from '../Utils/Context/teamProfileContext';
import Loader from '../Components/Loader/Loader';

function EditTeamView(props) {
    // const { teamProfile } = useContext(TeamProfileContext);
    const { teamProfile, isLoading, deleteProfile, createProfile } = useContext(TeamProfileContext);
    const [ posts, setPosts ] = useState([]);
    const handleProfileCreated = (data) =>{
        createProfile(data)
    }
    const handleProfileOnDelete = (id) =>{
        deleteProfile(id)
    }

    useEffect(() => {
        Axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nimbusmedia")
        .then(res =>{
            setPosts(res.data.items);
            
        })
     }, [])
    return (
        <div className="body-wrapper">
            <AdminHeader />
            {/* {
                isLoading ? <Loader /> : */}
                <> 
                <EditTeam profileData={teamProfile} onDelete={handleProfileOnDelete} onCreate={handleProfileCreated}/>
                <Footer footerBodyVisible={true} footerHeadVisible={true} />
                </>
            {/* }  */}
            
        </div>
    )
}

export default EditTeamView;
