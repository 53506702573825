import React from 'react';
import "./GalleryCard.css";

function GalleryCard(props) {

    const handleImgOnClick = (id) =>{
        props.handleImgOnClick(id);
    }
    const handleImgOnDelete = (id) =>{
        props.onDelete(id);
    }
    return (
        <div key={props.key} className="col-span-1 w-full relative hover:opacity-60 h-96">
            <svg style={{display: props.deleteBtnActive ? "block" : "none"}} onClick={handleImgOnDelete.bind(null, props.id)} xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-charity-color2 rounded-full absolute right-6 top-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
            <img src={props.galleryImg} alt="gallery" onClick={handleImgOnClick.bind(null, props.id)} className="w-full h-full object-cover" />
        </div>
    )
}

export default GalleryCard;
