import React from 'react'
import SigninFooter from '../Components/Footer/SigninFooter'
import Signup from '../Components/Signin/Signup';

function SignupPage() {
    return (

         <div className="body-wrapper">
            <Signup />
            <SigninFooter />
        </div>
    )
}

export default SignupPage;
