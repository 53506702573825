import React,{useState, useEffect, useRef} from "react";
import "./ContactUs.css";
import Loader from "../Loader";
import axios from "axios";

const ContactUs = () =>{
    const myRef = useRef(null);
    const [ contactInfo, setContactInfo ] = useState({
        name: "",
        email: "",
        message: ""
    });
    const [ loading, setLoading ] = useState(false);
    const [message, setMessage ] = useState({
        msg: "",
        state: 0
    });

    const handleOnChange = (e) =>{
        e.preventDefault();

        setContactInfo((prevState) =>{
            return{ ...prevState, [e.target.name]: e.target.value}
        })
    }

    const clearMessage = () =>{
        myRef.current.scrollIntoView(); 
        setContactInfo({
            name: "",
            email: "",
            message: ""
        });
        setTimeout(() => {
            setMessage(() =>{
                return {msg: "" , state: 0}
            });
            
        }, 3000);
    }

    const submitMessage = (e) =>{
        console.log(contactInfo);
        const payload = {
            email: contactInfo.email,
            message: contactInfo.message
        }
        setLoading(true);
        axios.post("https://nimbus-staging-client.onrender.com/api/v1/contact", payload)
        .then((res) =>{
            setLoading(false);
            setMessage(() =>{
                return {msg: "Message sent successfully!!!" , state: 1}
            });
            clearMessage();
        })
        .catch((err) =>{
            setMessage(() =>{
                return {msg: "error as occurred!!!" , state: 2}
            });
            clearMessage();
            console.log(err);
        })
        e.preventDefault();
    }

    return(
        <div className="ContactUs-container" id="contact" ref={myRef}>
            <div className="ContactUs-inner-container">
                <h1>Contact Us</h1>
                <p className="contact-us-para">(+234)-816-225-6038</p>
                <p className="contact-us-para">wale@nimbus.com.ng</p>
                <div className="message-container">
                    <p className="contact-us-para">Let’s showcase your brand to the world</p>
                        <form onSubmit={submitMessage}>
                            <div className="contact-input-wrapper">
                            <p className={`message py-2 ${message.state === 0 && "bg-white"} ${message.state === 1 && "text-green-300"} ${message.state === 2 && "text-red-500"}`}>{message.msg}</p>
                                <input className="contact-input" type="text" name="name" placeholder="Full Name" onChange={handleOnChange} value={contactInfo.name} autoComplete="off" required/>
                                <input className="contact-input" type="email" name="email" placeholder="Email address" onChange={handleOnChange} value={contactInfo.email} autoComplete="off" required/>
                                <textarea className="contact-textarea" name="message" placeholder="Tell us about your project" onChange={handleOnChange} value={contactInfo.message} autoComplete="off" required></textarea>
                            </div>
                            <button type="submit" disabled={loading} className={loading ? "opacity-40": "opacity-100"}>
                            {
                                loading &&
                                <Loader color="primary"/>
                            }
                                Send Message
                            </button>
                        </form>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;