import React from 'react'
import TimerSection from '../Charity/TimerSection'
import EntriesSecton from '../Charity/EntriesSecton'
import CreateRank from './CreateRank'
import ListOfRanks from "../Ranking/ListOfRanks";

function Ranking() {
    const tableData = [
        {
        sn: 1,
        nominations: "Sam Enterprise",
        email: "niyiade97@gmail.com",
        numberPool: "001"
        },
        {
        sn: 2,
        nominations: "Name nominated",
        email: "niyiade97@gmail.com",
        numberPool: "001"
        },
        {
        sn: 2,
        nominations: "Name nominated",
        email: "niyiade97@gmail.com",
        numberPool: "001"
        },
        {
        sn: 2,
        nominations: "Name nominated",
        email: "niyiade97@gmail.com",
        numberPool: "001"
        },
        {
        sn: 2,
        nominations: "Name nominated",
        email: "niyiade97@gmail.com",
        numberPool: "001"
        }

    ]
    return (
        <div className="md:text-about-p-color">
            <div className="bg-gradient-to-b from-brand-color2 via-brand-color3 to-brand-color4">
                <div className="xl:w-9/12 w-4/5 mx-auto bg-background-logo bg-no-repeat bg-contain bg-top">
                    <h1 className="text-center font-black md:py-5 py-3 text-brand-color1 md:text-4xl text-2xl opacity-80">
                        Ranking System
                    </h1>
                    <div className="block">
                       <CreateRank />
                    </div>
                </div>
                    <div className="w-9/12 m-auto">
                        <ListOfRanks/>
                    </div>
            </div>
        </div>
    )
}

export default Ranking
