import React from 'react'
import "./TeamImage.css";
import galleryImg1 from "../../assets/images/galleryImg1.png";
import { FaUserEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
function TeamImage(props) {
    return (
        <div className="team-img-outer-container hover:opacity-70">
            <div className="team-img-container" onClick={props.onClick}>
                <img src={props.image} alt="img" className='object-cover'/>
                <div className="team-title-container flex justify-end items-center absolute bottom-0 right-0 w-full text-right h-28 pr-5 text-white">
                    <div className="team-inner-title-container ">
                        <h1>{props.name}</h1>
                        <p>{props.position.includes("DIRECTORS")? "DIRECTOR" : props.position}</p>
                    </div>
                </div>
            </div>
            <div className="edit-team-container" style={{display: props.profileEdit ? "flex" : "none"}}>
                    <button onClick={props.onDelete}><span><MdDeleteForever /></span>Delete</button>
                    <button onClick={props.onEdit}><span><FaUserEdit /></span>Edit</button>
                </div>
        </div>
    )
}

export default TeamImage;
